export function authHeader() {
  // return authorization header with jwt token
  let token = localStorage.getItem("token");
  let tenant = localStorage.getItem("tenant");
  if (token) {
    return {
      Authorization: token,
      "Content-Type": "application/json",
      tenant: "" + tenant,
    };
  } else {
    return {
      "Content-Type": "application/json",
    };
  }
}
