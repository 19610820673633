import { defineStore } from "pinia";
import { authHeader } from "../utils/auth-header";
import { handleResponse } from "../utils/api_functions";
import { useAuthStore } from "@/stores/auth";
import config from "../../config";

export const useAppointmentStore = defineStore("appointments", {
  state: () => ({
    appointments: [],
  }),

  getters: {
    allAppointments: (state) => {
      let arr = [];
      const reverseDateRepresentation = (date) => {
        let parts = date.split("-");
        return `${parts[2]}/${parts[1]}/${parts[0]}`;
      };
      for (let i = 0; i < state.appointments.length; i++) {
        let obj = state.appointments[i];
        obj.day = reverseDateRepresentation(obj.dateTime.slice(0, 10));
        arr.push(obj);
      }

      return arr;
    },
    myAppointments: (state) => {
      const auth = useAuthStore();
      let arr = [];
      //* Reversing the date format function
      const reverseDateRepresentation = (date) => {
        let parts = date.split("-");
        return `${parts[2]}/${parts[1]}/${parts[0]}`;
      };
      //* Filtering only upcoming appointments
      const isAfterYesterday = (date) => {
        const yesterday = new Date();
        const appointment_date = new Date(date);
        yesterday.setDate(yesterday.getDate() - 1);
        return appointment_date > yesterday;
      };
      for (let i = 0; i < state.appointments.length; i++) {
        if (auth.user !== null && state.appointments[i].employeeId !== null) {
          if (auth.user._id === state.appointments[i].employeeId._id) {
            if (isAfterYesterday(state.appointments[i].dateTime)) {
              let obj = state.appointments[i];
              obj.day = reverseDateRepresentation(obj.dateTime.slice(0, 10));
              arr.push(obj);
            }
          }
        }
      }
      //* Grouping the appointments by day
      const groups = arr.reduce((groups, item) => {
        const date = item.day;
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(item);
        return groups;
      }, {});

      //* Adding it to an array format
      const groupArrays = Object.keys(groups).map((date) => {
        return {
          date,
          appointments: groups[date],
        };
      });

      groupArrays.sort(function (a, b) {
        var aa = a.date.split("/").reverse().join(),
          bb = b.date.split("/").reverse().join();
        return aa < bb ? -1 : aa > bb ? 1 : 0;
      });

      return groupArrays;
    },
    calendarAppointments: (state) => {
      const auth = useAuthStore();
      let arr = [];
      for (let i = 0; i < state.appointments.length; i++) {
        let obj = {
          id: state.appointments[i]._id,
          startDate: state.appointments[i].dateTime,
          title: state.appointments[i].title,
          type: state.appointments[i].type,
          style: "font-weight: 500;",
        };
        if (state.appointments[i].endDateTime !== null) {
          obj.endDate = state.appointments[i].endDateTime;
        }
        if (auth.user !== null) {
          if (state.appointments[i].employeeId === null) {
            obj.style = "background-color: #61a5c2; font-weight: 500;";
          } else if (state.appointments[i].status === "CANCELLED") {
            obj.style = "background-color: #e5383b; font-weight: 500;";
          } else if (state.appointments[i].status === "COMPLETED") {
            obj.style = "background-color: #06d6a0; font-weight: 500;";
          } else if (
            state.appointments[i].employeeId !== null &&
            auth.user._id === state.appointments[i].employeeId._id
          ) {
            switch (state.appointments[i].type) {
              case "APPOINTMENT":
                obj.style = "background-color: #fc88c7; font-weight: 500;";
                break;
              case "CALL_BACK":
                obj.style = "background-color: #2a9d8f; font-weight: 500;";
                break;
              case "REMINDER":
                obj.style = "background-color: #ffb703; font-weight: 500;";
                break;
              default:
                break;
            }
          }
        }
        arr.push(obj);
      }
      return arr;
    },
    myCalendarAppointments: (state) => {
      const auth = useAuthStore();
      let arr = [];
      for (let i = 0; i < state.appointments.length; i++) {
        if (auth.user !== null && state.appointments[i].employeeId !== null) {
          if (auth.user._id === state.appointments[i].employeeId._id) {
            let obj = {
              id: state.appointments[i]._id,
              startDate: state.appointments[i].dateTime,
              title: state.appointments[i].title,
              type: state.appointments[i].type,
              style: "font-weight: 500;",
            };
            if (state.appointments[i].status === "CANCELLED") {
              obj.style = "background-color: #e5383b; font-weight: 500;";
            } else if (state.appointments[i].status === "COMPLETED") {
              obj.style = "background-color: #06d6a0; font-weight: 500;";
            } else {
              switch (state.appointments[i].type) {
                case "APPOINTMENT":
                  obj.style = "background-color: #fc88c7; font-weight: 500;";
                  break;
                case "CALL_BACK":
                  obj.style = "background-color: #2a9d8f; font-weight: 500;";
                  break;
                case "REMINDER":
                  obj.style = "background-color: #ffb703; font-weight: 500;";
                  break;
                default:
                  break;
              }
            }
            if (state.appointments[i].endDateTime !== null) {
              obj.endDate = state.appointments[i].endDateTime;
            }

            arr.push(obj);
          }
        }
      }
      return arr;
    },
    allCallBacks: (state) => {
      let arr = [];
      const reverseDateRepresentation = (date) => {
        let parts = date.split("-");
        return `${parts[2]}/${parts[1]}/${parts[0]}`;
      };
      for (let i = 0; i < state.appointments.length; i++) {
        if (
          state.appointments[i].type === "CALL_BACK" &&
          state.appointments[i].status === "ACTIVE"
        ) {
          let obj = state.appointments[i];
          obj.day = reverseDateRepresentation(obj.dateTime.slice(0, 10));
          arr.push(obj);
        }
      }
      arr.sort(function (a, b) {
        var aa = a.day.split("/").reverse().join(),
          bb = b.day.split("/").reverse().join();
        return aa < bb ? -1 : aa > bb ? 1 : 0;
      });
      return arr;
    },
    userCallBacks: (state) => {
      const auth = useAuthStore();
      let arr = [];
      const reverseDateRepresentation = (date) => {
        let parts = date.split("-");
        return `${parts[2]}/${parts[1]}/${parts[0]}`;
      };
      for (let i = 0; i < state.appointments.length; i++) {
        if (
          state.appointments[i].type === "CALL_BACK" &&
          state.appointments[i].status === "ACTIVE"
        ) {
          if (auth.user._id === state.appointments[i].employeeId._id) {
            let obj = state.appointments[i];
            obj.day = reverseDateRepresentation(obj.dateTime.slice(0, 10));
            arr.push(obj);
          }
        }
      }
      arr.sort(function (a, b) {
        var aa = a.day.split("/").reverse().join(),
          bb = b.day.split("/").reverse().join();
        return aa < bb ? -1 : aa > bb ? 1 : 0;
      });
      return arr;
    },
  },

  actions: {
    async getAllAppointments() {
      try {
        const requestOptions = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(
          `${config.apiUrl}/tenant/appointments`,
          requestOptions
        )
          .then(handleResponse)
          .then((res) => {
            this.appointments = res;
            return res;
          });
      } catch (error) {
        console.error(error);
      }
    },

    async createAppointment(appointmentData) {
      try {
        const requestOptions = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(appointmentData),
        };
        return await fetch(
          `${config.apiUrl}/tenant/appointments/new`,
          requestOptions
        )
          .then(handleResponse)
          .then((res) => {
            // this.appointments.push(res);
            return res;
          });
      } catch (error) {
        console.error(error);
      }
    },

    async updateAppointment(updatedAppointment) {
      try {
        const requestOptions = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(updatedAppointment),
        };
        return await fetch(
          `${config.apiUrl}/tenant/appointments/update`,
          requestOptions
        )
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        console.error(error);
      }
    },
  },
});
