<template>
  <v-row justify="center">
    <v-card width="650" class="pa-4 overflow-y-auto" max-height="715px">
      <v-card-title class="pa-4 pb-0">
        <span class="text-h5 mb-3">Create an Appointment</span>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-container>
          <v-row>
            <v-col class="mx-0 px-0 mb-0 pb-0">
              <v-autocomplete
                v-model="selectedProfileId"
                :items="profiles"
                item-title="name"
                item-value="_id"
                label="Profile"
              ></v-autocomplete>
            </v-col>
            <v-col class="pr-0">
              <v-autocomplete
                v-model="employee"
                :items="auth.activeEmployees"
                item-title="fullName"
                item-value="_id"
                label="Employee (optional)"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mb-1">
            <v-autocomplete
              v-model="appointmentType"
              :items="appointmentsTypes"
              item-title="text"
              item-value="value"
              label="Type"
            ></v-autocomplete>
          </v-row>
          <v-row class="mb-1">
            <v-text-field
              v-model="title"
              label="Title"
              required
              type="text"
              counter="50"
              @input="
                title.length > 50 ? (title = title.substring(0, 50)) : title
              "
            ></v-text-field>
          </v-row>
          <v-row>
            <v-textarea
              label="Description"
              v-model="description"
              auto-grow
              outlined
              rows="3"
              row-height="25"
              counter="150"
              @input="
                description.length > 150
                  ? (description = description.substring(0, 150))
                  : description
              "
            ></v-textarea>
          </v-row>
          <v-row class="mb-0 pb-0">
            <v-select
              v-model="duration"
              :items="durationItems"
              item-title="text"
              item-value="value"
              label="Duration (optional)"
            ></v-select>
          </v-row>
          <Datepicker v-model="date" teleportCenter />
        </v-container>
      </v-card-text>
      <v-card-actions class="pa-4 pt-0">
        <v-spacer></v-spacer>
        <v-row justify="end">
          <v-btn size="large" variant="flat" @click="close"> close </v-btn>
          <v-btn
            size="large"
            variant="flat"
            @click="createAppointment"
            :disabled="
              title === '' ||
              description === '' ||
              !appointmentType ||
              !date ||
              !selectedProfileId
            "
          >
            Create
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script setup>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { ref, defineEmits, defineProps } from "vue";
import { useAppointmentStore } from "@/stores/appointment";
import { useAuthStore } from "@/stores/auth";
import { useProfileStore } from "@/stores/profile";
import { AppointmentsTypeEnum } from "../enums";

const props = defineProps({
  profileId: String,
  type: String,
  profileStatus: String,
});
const emits = defineEmits(["close", "clear"]);
const appointment = useAppointmentStore();
const auth = useAuthStore();
const profile = useProfileStore();
const title = ref("");
const description = ref("");
const appointmentType = ref(props.type);
const selectedProfileId = ref(props.profileId);
const newProfileStatus = ref(props.profileStatus);
const employee = ref("");
const date = ref();
const profiles = ref([]);

profile.getAllProfiles().then(() => {
  Object.keys(profile.profiles).forEach(function (key) {
    const item = { ...profile.profiles[key] };
    profiles.value.push(item);
  });
});
const appointmentsTypes = ref([]);
Object.keys(AppointmentsTypeEnum).forEach(function (key) {
  const item = { ...AppointmentsTypeEnum[key] };
  item.value = key;
  appointmentsTypes.value.push(item);
});

const duration = ref();
const durationItems = [
  {
    text: "30 min",
    value: 30,
  },
  {
    text: "60 min",
    value: 60,
  },
  {
    text: "90 min",
    value: 90,
  },
  {
    text: "2 hours",
    value: 120,
  },
  {
    text: "2.5 hours",
    value: 150,
  },
  {
    text: "3 hours",
    value: 180,
  },
  {
    text: "3.5 hours",
    value: 210,
  },
  {
    text: "4 hours",
    value: 240,
  },
];

// Functions
async function createAppointment() {
  let employeeId = null;
  if (employee.value !== "") {
    employeeId = employee.value;
  }
  const newAppointment = {
    employeeId: employeeId,
    profileId: selectedProfileId.value,
    title: title.value,
    description: description.value,
    type: appointmentType.value,
    status: "ACTIVE",
    dateTime: date.value.toISOString(),
  };
  if (duration.value) {
    let endDate = new Date(
      date.value.setMinutes(date.value.getMinutes() + duration.value)
    );
    newAppointment.endDateTime = endDate.toISOString();
  }

  await appointment.createAppointment(newAppointment);
  //* Update profile status and phase
  let profileData = {
    leadState: "APPOINTMENT",
    phase: "FIRST_APPOINTMENT",
  };
  let updatedProfile = {
    profile: selectedProfileId.value,
    update: profileData,
  };
  await profile.updateProfile(updatedProfile);
  await appointment.getAllAppointments().then(() => {
    let data = {
      profileId: selectedProfileId.value,
      status: newProfileStatus.value,
    };
    emits("submit", data);
  });
}
function close() {
  emits("close");
}
</script>

<style scoped></style>
