const PlanningEnum = Object.freeze({
  LINDA: { name: "L!nda" },
  FOOKS: { name: "Fooks" },
  SHIFTBASE: { name: "Shiftbase" },
  NOSTRADAMUS: { name: "Nostradamus" },
  HOREKO: { name: "Horeko" },
  BOOQ: { name: "Booq" },
  EITJE: { name: "Eitje" },
  OTHER: { name: "Anders" },
});

export default PlanningEnum;
