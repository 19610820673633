import AppointmentsStatusEnum from "../enums/modules/AppointmentsStatusEnum";

export function appointmentStatus(string) {
  let appointmentStatuses = [];
  Object.keys(AppointmentsStatusEnum).forEach(function (key) {
    const item = { ...AppointmentsStatusEnum[key] };
    if (item.subText) {
      item.text += " - " + item.subText;
    }
    item.value = key;
    appointmentStatuses.push(item);
  });
  for (let i = 0; i < appointmentStatuses.length; i++) {
    if (appointmentStatuses[i].value === string) {
      return appointmentStatuses[i].text.toUpperCase();
    }
  }
}
