import { defineStore } from "pinia";
//import config from "../../config";

export const useAlertsStore = defineStore("alerts", {
  state: () => ({
    display: false,
    type: null,
    message: null,
  }),

  getters: {},

  actions: {
    success(message) {
      this.type = "success";
      this.message = message;
      this.display = true;
      //! This does not work
      // setTimeout(function () {
      //   this.type = null;
      //   this.message = null;
      //   this.display = false;
      // }, 5500);
    },
    info(message) {
      this.type = "info";
      this.message = message;
      this.display = true;
      //! This does not work
      // setTimeout(function () {
      //   this.type = null;
      //   this.message = null;
      //   this.display = false;
      // }, 5500);
    },
    warn(message) {
      this.type = "warning";
      this.message = message;
      this.display = true;
      //! This does not work
      // setTimeout(function () {
      //   this.type = null;
      //   this.message = null;
      //   this.display = false;
      // }, 5500);
    },
    error(message) {
      this.type = "error";
      this.message = message;
      this.display = true;
      //! This does not work
      // setTimeout(function () {
      //   this.type = null;
      //   this.message = null;
      //   this.display = false;
      // }, 5500);
    },
    clear() {
      this.type = null;
      this.message = null;
      this.display = false;
    },
  },
});
