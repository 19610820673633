<template>
  <div>
    <v-chip v-if="item" class="pr-0" :color="color ? color : item.color"
      >{{ item[itemText] }}<v-icon icon="mdi-menu-down"></v-icon
      ><v-icon @click.stop="cleared" class="mr-2" icon="mdi-close"></v-icon
    ></v-chip>
    <v-chip v-else class="mr-1"
      >-<v-icon end icon="mdi-menu-down"></v-icon>
    </v-chip>
    <v-menu activator="parent">
      <v-list @click:select="menuItemSelected">
        <v-list-item
          v-for="(mItem, index) in menuItems"
          :key="index"
          :value="mItem[itemValue]"
        >
          <v-list-item-title>{{ mItem[itemText] }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
defineProps({
  menuItems: Array,
  item: Object,
  itemText: String,
  itemValue: String,
  color: String,
});
const emit = defineEmits(["updated", "cleared"]);

function menuItemSelected(data) {
  emit("updated", data.id);
}

function cleared() {
  emit("cleared");
}
</script>

<style scoped></style>
