const PayrollEnum = Object.freeze({
  CONNEXIE: { name: "Connexie" },
  KOLIBRIE: { name: "Kolibrie" },
  METTOM: { name: "Mettom" },
  STAFFABLE: { name: "Staffable" },
  JAM: { name: "Jam!" },
  PERSOONALITY: { name: "Persoonality" },
  MULTIMAN: { name: "Multimaa" },
  FOOKS: { name: "Fooks" },
  OTHER: { name: "Anders" },
});

export default PayrollEnum;
