import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/stores/auth";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import LeadsView from "../views/LeadsView.vue";
import EmployeesView from "../views/EmployeesView.vue";
import AppointmentsView from "../views/AppointmentsView.vue";
import CustomersView from "../views/CustomersView.vue";
import AllProfilesView from "../views/AllProfilesView.vue";
import ProspectsView from "../views/ProspectsView.vue";
import StatisticsView from "../views/StatisticsView.vue";
//import LoginView from "../views/LoginView.vue";

const authCheck = (to, from, next) => {
  const auth = useAuthStore();

  const fn = () => {
    if (auth.user) {
      // if (auth.user.role == 1) {
      //   next();
      //   return;
      // }
      next();
      return;
    } else {
      next("/login");
    }
  };

  if (!auth.loading) {
    return fn();
  }

  if (auth.user) {
    return fn();
  }

  //! Throws NEXT loop warning
  auth.$subscribe(() => {
    return fn();
  });
};

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    beforeEnter: authCheck,
  },
  {
    path: "/leads",
    name: "leads",
    component: LeadsView,
    beforeEnter: authCheck,
  },
  {
    path: "/customers",
    name: "customers",
    component: CustomersView,
    beforeEnter: authCheck,
  },
  {
    path: "/profiles",
    name: "profiles",
    component: AllProfilesView,
    beforeEnter: authCheck,
  },
  {
    path: "/prospects",
    name: "prospects",
    component: ProspectsView,
    beforeEnter: authCheck,
  },
  {
    path: "/employees",
    name: "employees",
    component: EmployeesView,
    beforeEnter: authCheck,
  },
  {
    path: "/about",
    name: "about",
    beforeEnter: authCheck,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/appointments",
    name: "appointments",
    component: AppointmentsView,
    beforeEnter: authCheck,
  },
  {
    path: "/statistics",
    name: "statistics",
    component: StatisticsView,
    beforeEnter: authCheck,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
