const ProfilePhaseReasonEnum = Object.freeze({
  NOT_INTRESTED: { text: "Geen interesse" },
  STOPPED: { text: "Gestopt/verkocht" },
  NO_CHANGE: { text: "Blijft bij huidige partner" },
  OTHER_PARTY: { text: "Gaat naar andere partij" },
  EXPENSIVE: { text: "Te duur" },
  OTHER: { text: "Anders" },
});

export default ProfilePhaseReasonEnum;
