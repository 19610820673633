<template>
  <v-container fluid class="pa-0">
    <v-overlay v-model="overlay" content-class="custom-overlay">
      <ProfileOverlayCardVue
        :profile="activeProfile"
        :openEditDialog="toEdit"
        @close="overlay = false"
      />
    </v-overlay>
    <v-dialog v-model="csvUploadDialog">
      <v-card width="600" class="pa-2">
        <v-container fluid>
          <v-row align-content="center">
            <v-file-input
              v-model="csvFile"
              accept=".csv"
              label="CSV Upload"
            ></v-file-input>
          </v-row>
          <v-row justify="center">
            <v-btn color="success" @click="fileUpload()">Upload</v-btn>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="createProfileDialog"
      ><CreateProfileDialogVue @close="createProfileDialog = false"
    /></v-dialog>

    <!-- Bulk action dialog -->
    <v-dialog v-model="bulkActionsDialog">
      <BulkActionsDialog
        @clear="clearSelectedProfiles"
        :selectedProfiles="selectedProfiles"
        @close="bulkActionsDialog = false"
      />
    </v-dialog>

    <v-dialog v-model="qrCodeDialog">
      <VueVCard
        lastName="- CLLBR Sales"
        :firstName="contactInformation.name"
        :workPhone="contactInformation.workPhone"
        :workEmail="contactInformation.email"
        :homePhone="contactInformation.homePhone"
        :notes="contactInformation.notes"
        :fondness="contactInformation.fondness"
        :profileId="contactInformation.profileId"
        :size="400"
        @close="qrCodeDialog = false"
      />
    </v-dialog>
    <v-dialog persistent v-model="openAppointmentDialog"
      ><CreateAppointmentDialog
        @close="openAppointmentDialog = false"
        @submit="
          (data) => {
            submittedAppointment(data, 'leadState');
          }
        "
        :profileId="selectedProfile"
        :type="appointmentType"
        :profileStatus="profileStatus"
    /></v-dialog>
    <v-menu
      activator="#menu-activator"
      :close-on-content-click="false"
      location="start"
    >
      <v-card width="320" class="pa-2">
        <v-chip
          :color="item.value ? 'green' : 'red'"
          v-for="item in columnsItems"
          :key="item.column"
          class="mr-1 mb-1"
          filter
          @click="updateColumnState(item)"
          >{{ item.text }}</v-chip
        >
      </v-card>
    </v-menu>

    <v-card elevation="0" class="pa-4">
      <v-card-title class="pr-0 pl-0 pb-8">
        <v-row class="pr-2 pl-2">
          <v-col>
            <h1 class="pt-2">{{ title }}</h1>
          </v-col>
          <v-col cols="auto" v-if="actionSection">
            <v-row>
              <v-btn
                variant="text"
                icon
                color="primary"
                @click="showBulkActionsDialog"
              >
                <v-icon>mdi-pencil-box-multiple-outline </v-icon>
                <v-tooltip activator="parent" location="start"
                  >Bulk actie</v-tooltip
                >
              </v-btn>
              <v-btn
                variant="text"
                icon
                color="primary"
                @click="csvUploadDialog = true"
              >
                <v-icon>mdi-upload</v-icon>
                <v-tooltip activator="parent" location="start"
                  >Leads Uploaden</v-tooltip
                >
              </v-btn>
              <v-btn variant="text" icon color="primary" id="menu-activator">
                <v-icon>mdi-table-eye</v-icon>
                <v-tooltip activator="parent" location="start"
                  >Kies kollomen</v-tooltip
                >
              </v-btn>
              <v-btn
                variant="text"
                icon
                color="primary"
                @click="createProfileDialog = true"
              >
                <v-icon>mdi-plus</v-icon>
                <v-tooltip activator="parent" location="start"
                  >Nieuwe Profiel</v-tooltip
                >
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card rounded="xl" class="pa-2 mb-8 custom-card">
        <v-container class="pt-6 pb-6">
          <v-row>
            <v-col cols="5"><h3 class="pl-1">Wat zoek je?</h3></v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="pt-2"
              ><v-text-field
                class="custom-text-field"
                placeholder="Zoek op elke text"
                density="comfortable"
                variant="solo"
                hide-details
                single-line
                v-model="searchTerms.text"
                prepend-inner-icon="mdi-magnify"
              >
                <template v-slot:append-inner>
                  <v-icon @click.stop="clearSearchFilter()" color="red"
                    >mdi-close</v-icon
                  >
                </template></v-text-field
              ></v-col
            >
            <v-col cols="4" class="pt-2"
              ><v-select
                :items="leadStatuses"
                item-title="text"
                item-value="value"
                class="custom-select"
                label="Status"
                variant="solo"
                density="comfortable"
                multiple
                v-model="searchTerms.leadState"
                hide-details
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">{{ item.title }}</span>
                  <span v-if="index === 1" class="grey--text">
                    (+{{ searchTerms.leadState.length - 1 }} others)
                  </span>
                </template>
                <template v-slot:append-inner>
                  <v-icon @click.stop="clearStateFilter()" color="red"
                    >mdi-close</v-icon
                  >
                </template></v-select
              >
            </v-col>
            <v-col cols="3" class="pt-2"
              ><v-select
                :items="customEmployees"
                item-title="fullName"
                item-value="_id"
                class="custom-select"
                label="Beller"
                variant="solo"
                density="comfortable"
                multiple
                v-model="searchTerms.employeeCaller"
                hide-details
                ><template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">{{ item.title }}</span>
                  <span v-if="index === 1" class="grey--text">
                    (+{{ searchTerms.employeeCaller.length - 1 }} others)
                  </span>
                </template>

                <template v-slot:append-inner>
                  <v-icon @click.stop="clearCallerFilter()" color="red"
                    >mdi-close</v-icon
                  >
                </template>
              </v-select></v-col
            >
            <v-col cols="1" class="pt-2"
              ><v-btn
                color="secondary"
                @click="search()"
                rounded="lg"
                icon="mdi-magnify"
              ></v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col cols="4" class="pt-2"
              ><v-select
                :items="profilePhases"
                item-title="text"
                item-value="value"
                class="custom-select"
                label="Fase"
                variant="solo"
                density="comfortable"
                multiple
                v-model="searchTerms.extraPhases"
                hide-details
                ><template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">{{ item.title }}</span>
                  <span v-if="index === 1" class="grey--text">
                    (+{{ searchTerms.extraPhases.length - 1 }} others)
                  </span>
                </template>
                <template v-slot:append-inner>
                  <v-icon @click.stop="clearPhasesFilter()" color="red"
                    >mdi-close</v-icon
                  >
                </template>
              </v-select></v-col
            >
            <v-col cols="4" class="pt-2"
              ><v-select
                :items="auth.activeEmployees"
                item-title="fullName"
                item-value="_id"
                class="custom-select"
                label="Accountmanager"
                variant="solo"
                density="comfortable"
                multiple
                v-model="searchTerms.accountManagers"
                hide-details
                ><template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">{{ item.title }}</span>
                  <span v-if="index === 1" class="grey--text">
                    (+{{ searchTerms.accountManagers.length - 1 }} others)
                  </span>
                </template>
                <template v-slot:append-inner>
                  <v-icon @click.stop="clearAccManagersFilter()" color="red"
                    >mdi-close</v-icon
                  >
                </template>
              </v-select></v-col
            >
            <v-col cols="3" class="pt-2"
              ><v-select
                :items="segments"
                item-title="text"
                item-value="value"
                class="custom-select"
                label="Segment"
                variant="solo"
                density="comfortable"
                multiple
                v-model="searchTerms.segment"
                hide-details
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">{{ item.title }}</span>
                  <span v-if="index === 1" class="grey--text">
                    (+{{ searchTerms.segment.length - 1 }} others)
                  </span>
                </template>
                <template v-slot:append-inner>
                  <v-icon @click.stop="clearSegmentFilter()" color="red"
                    >mdi-close</v-icon
                  >
                </template></v-select
              ></v-col
            >
            <v-col cols="1" class="pt-2">
              <v-btn
                icon="mdi-close-thick"
                rounded="lg"
                color="secondary"
                @click="clear()"
              ></v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col cols="4" class="pt-2"
              ><v-select
                :items="provinces"
                item-title="text"
                item-value="value"
                class="custom-select"
                label="Provincie"
                variant="solo"
                density="comfortable"
                multiple
                v-model="searchTerms.provinces"
                hide-details
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">{{ item.title }}</span>
                  <span v-if="index === 1" class="grey--text">
                    (+{{ searchTerms.provinces.length - 1 }} others)
                  </span>
                </template>
                <template v-slot:append-inner>
                  <v-icon @click.stop="clearProvinceFilter()" color="red"
                    >mdi-close</v-icon
                  >
                </template></v-select
              >
            </v-col>
            <v-col cols="4" class="pt-2"
              ><v-select
                :items="sectors"
                item-title="text"
                item-value="value"
                class="custom-select"
                label="Sector"
                variant="solo"
                density="comfortable"
                v-model="searchTerms.sector"
                hide-details
              >
                <template v-slot:append-inner>
                  <v-icon @click.stop="clearSectorFilter()" color="red"
                    >mdi-close</v-icon
                  >
                </template>
              </v-select></v-col
            >
            <v-col cols="3" class="">
              <Datepicker
                v-model="searchTerms.dateRange"
                range
                :presetRanges="presetRanges"
                :enableTimePicker="false"
                placeholder="Aangemaakt"
            /></v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-card rounded="xl" class="pa-2 custom-card">
        <v-table>
          <thead>
            <tr>
              <th>
                <!-- <v-checkbox-btn
                  v-model="selectAllLeads"
                ></v-checkbox-btn> -->
              </th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th style="min-width: 100px" v-if="columnVisible('phase')">
                Fase
              </th>
              <th style="min-width: 100px" v-if="columnVisible('leadStatus')">
                Status
              </th>
              <th style="min-width: 100px" v-if="columnVisible('employee')">
                Accountmanager
              </th>
              <th style="min-width: 100px" v-if="columnVisible('caller')">
                Beller
              </th>
              <th v-if="columnVisible('kvk')">KvK</th>
              <th v-if="columnVisible('name')">Naam</th>
              <th v-if="columnVisible('place')">Plaats</th>
              <th v-if="columnVisible('province')">Provincie</th>
              <th v-if="columnVisible('cp_initials')">CP Voorletters</th>
              <th v-if="columnVisible('cp_last_name')">CP Acthernaam</th>
              <th v-if="columnVisible('cp_gender')">CP Geslacht</th>
              <th v-if="columnVisible('tel')">Tel</th>
              <th v-if="columnVisible('cel')">Cel</th>
              <th v-if="columnVisible('email')">Mail</th>
              <th v-if="columnVisible('employees')">Werkenmers</th>
              <th v-if="columnVisible('last_contact')">Laatste Contact</th>
              <th v-if="columnVisible('payroll')">Payroll</th>
              <th v-if="columnVisible('planning')">Planning</th>
              <th v-if="columnVisible('sector')">Sector</th>
              <th v-if="columnVisible('segment')">Segment</th>
              <th v-if="columnVisible('firstContact')">Eerste Contact</th>
              <th v-if="columnVisible('relationPerc')">Won %</th>
              <th v-if="columnVisible('noLocations')">Locaties</th>
              <th v-if="columnVisible('notes')">Notes</th>
              <th v-if="columnVisible('createdAt')">Aangemaakt</th>
            </tr>
          </thead>
          <tbody v-if="filteredProfiles">
            <tr
              v-for="item in filteredProfiles.slice(
                (setting.pageNo - 1) * displayedProfilesNumber,
                setting.pageNo * displayedProfilesNumber
              )"
              :key="item._id"
            >
              <td>
                <v-checkbox-btn
                  v-model="selectedProfiles"
                  :value="item"
                ></v-checkbox-btn>
              </td>
              <td class="pl-0 pr-0">
                <v-icon
                  v-if="item.fondness != 'NORMAL'"
                  :icon="formatFondness(item.fondness).icon"
                  :color="formatFondness(item.fondness).color"
                  size="small"
                ></v-icon>
              </td>
              <td class="pl-0 pr-0">
                <v-btn
                  class="action-button"
                  @click.stop="rowClick(item)"
                  variant="plain"
                  icon="mdi-open-in-new"
                  size="small"
                ></v-btn>
              </td>
              <td class="pl-0 pr-0">
                <v-btn
                  class="action-button"
                  @click.stop="editClick(item)"
                  variant="plain"
                  icon="mdi-pencil"
                  size="small"
                ></v-btn>
              </td>
              <td class="pl-0 pr-0">
                <v-btn
                  class="action-button"
                  @click.stop="displayQrCode(item)"
                  variant="plain"
                  icon="mdi-qrcode"
                  size="small"
                ></v-btn>
              </td>
              <td v-if="columnVisible('phase')">
                <div>
                  <DropdownChip
                    :menuItems="profilePhases"
                    :item="formatPhase(item.phase)"
                    itemText="text"
                    itemValue="value"
                    @updated="
                      (data) => {
                        inTableEdit(data, item._id, 'phase');
                      }
                    "
                    @cleared="
                      (data) => {
                        inTableClearSelection(item._id, 'phase');
                      }
                    "
                  />
                </div>
              </td>
              <td v-if="columnVisible('leadStatus')">
                <div>
                  <DropdownChip
                    :menuItems="leadStatuses"
                    :item="formatState(item.leadState)"
                    itemText="text"
                    itemValue="value"
                    @updated="
                      (data) => {
                        inTableEdit(data, item._id, 'leadState');
                      }
                    "
                    @cleared="
                      (data) => {
                        inTableClearSelection(item._id, 'leadState');
                      }
                    "
                  />
                </div>
              </td>
              <td v-if="columnVisible('employee')">
                <div v-if="item.employeeSales">
                  <div>
                    <DropdownChip
                      :menuItems="auth.activeEmployees"
                      :item="auth.employeeById(item.employeeSales)"
                      itemText="firstName"
                      itemValue="_id"
                      color="secondary"
                      @updated="
                        (data) => {
                          inTableEdit(data, item._id, 'employeeSales');
                        }
                      "
                      @cleared="
                        (data) => {
                          inTableClearSelection(item._id, 'employeeSales');
                        }
                      "
                    />
                  </div>
                </div>
                <v-btn
                  v-else
                  size="small"
                  rounded="pill"
                  color="rgba(var(--v-theme-secondary), .2)"
                  @click.stop="claimSales(item._id)"
                  >Claim</v-btn
                >
              </td>
              <td v-if="columnVisible('caller')">
                <div v-if="item.employeeCaller">
                  <div>
                    <DropdownChip
                      :menuItems="auth.activeEmployees"
                      :item="auth.employeeById(item.employeeCaller)"
                      itemText="firstName"
                      itemValue="_id"
                      color="primary"
                      @updated="
                        (data) => {
                          inTableEdit(data, item._id, 'employeeCaller');
                        }
                      "
                      @cleared="
                        (data) => {
                          inTableClearSelection(item._id, 'employeeCaller');
                        }
                      "
                    />
                  </div>
                </div>
                <v-btn
                  v-else
                  size="small"
                  rounded="pill"
                  color="rgba(var(--v-theme-primary), .4)"
                  @click.stop="claimCaller(item._id)"
                  >Claim</v-btn
                >
              </td>
              <td v-if="columnVisible('kvk')">{{ item.kvk }}</td>
              <td v-if="columnVisible('name')">{{ item.name }}</td>
              <td v-if="columnVisible('place')">{{ item.address?.region }}</td>
              <td v-if="columnVisible('province')">
                {{ formatProvince(item.address?.province) }}
              </td>
              <td v-if="columnVisible('cp_initials')">
                {{ item.contactPerson?.initials }}
              </td>
              <td v-if="columnVisible('cp_last_name')">
                {{ item.contactPerson?.lastName }}
              </td>
              <td v-if="columnVisible('cp_gender')">
                {{ item.contactPerson?.gender }}
              </td>
              <td v-if="columnVisible('tel')">{{ item.phoneNum }}</td>
              <td v-if="columnVisible('cel')">
                {{ item.contactPerson?.celNumber }}
              </td>
              <td v-if="columnVisible('email')">{{ item.email }}</td>
              <td v-if="columnVisible('employees')">
                {{ item.employeesInService?.min }} -
                {{ item.employeesInService?.max }}
              </td>
              <td v-if="columnVisible('last_contact')">
                {{ formatDate(item.lastContactDate) }}
              </td>
              <td v-if="columnVisible('payroll')">
                {{ formatCurrentPayroll(item.currentPayroll) }}
              </td>
              <td v-if="columnVisible('planning')">
                {{ formatCurrentPlan(item.currentPlan) }}
              </td>
              <td v-if="columnVisible('sector')">
                {{ formatSector(item.sector) }}
              </td>
              <td v-if="columnVisible('segment')">
                {{ formatSegment(item.segment) }}
              </td>
              <td v-if="columnVisible('firstContact')">
                {{ formatDate(item.firstContactDate) }}
              </td>
              <td v-if="columnVisible('relationPerc')">
                {{ item.relationPerc }}
              </td>
              <td v-if="columnVisible('noLocations')">
                {{ item.noLocations }}
              </td>
              <td v-if="columnVisible('notes')">
                <v-icon>mdi-note-multiple</v-icon>
                <v-tooltip activator="parent" location="start">{{
                  item.notes
                }}</v-tooltip>
              </td>
              <td v-if="columnVisible('createdAt')">
                {{ formatDate(item.createdAt) }}
              </td>
            </tr>
          </tbody>
        </v-table>
        <v-row class="my-2" justify="space-between">
          <v-col class="d-flex align-center justify-center" cols="1">
            <div class="" style="max-width: 100px">
              <span>Aantal: </span>
              <span v-if="filteredProfiles">{{ filteredProfiles.length }}</span>
            </div>
          </v-col>
          <v-col cols="auto">
            <v-pagination
              v-model="setting.pageNo"
              :length="pageCount"
              :total-visible="10"
            ></v-pagination>
          </v-col>

          <v-col cols="2" class="d-flex">
            <v-text-field
              v-model="setting.pageNo"
              hide-details
              single-line
              type="number"
              min="1"
              :max="pageCount"
              class="mr-2"
            />

            <v-select
              :items="visibleProfileNumbers"
              item-title="text"
              item-value="value"
              hide-details
              single-line
              v-model="displayedProfilesNumber"
            ></v-select>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
  </v-container>
</template>
<script>
import {
  LeadStateEnum,
  PayrollEnum,
  PlanningEnum,
  ProfileFondnessEnum,
  ProfilePhaseEnum,
  ProfilePhaseReasonEnum,
  SectorEnum,
  SegmentEnum,
  ProvincesEnum,
} from "../enums";
import { ref } from "vue";
import { useProfileStore } from "@/stores/profile";
import { useAuthStore } from "@/stores/auth";
import { useSettingStore } from "@/stores/setting";
import { useAlertsStore } from "@/stores/alerts";
import ProfileOverlayCardVue from "@/components/ProfileOverlayCard.vue";
import CreateProfileDialogVue from "@/components/CreateProfileDialog.vue";
import DropdownChip from "@/components/DropdownChip.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import VueVCard from "@/components/VueVCard.vue";
import CreateAppointmentDialog from "@/components/CreateAppointmentDialog.vue";
import BulkActionsDialog from "@/components/BulkActionsDialog.vue";

import {
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear,
  subMonths,
} from "date-fns";
export default {
  name: "App",
  components: {
    ProfileOverlayCardVue,
    CreateProfileDialogVue,
    Datepicker,
    VueVCard,
    DropdownChip,
    CreateAppointmentDialog,
    BulkActionsDialog,
  },
  props: {
    title: String,
    actionSection: Boolean,
    filterPhases: Array,
  },

  setup(props) {
    const profile = useProfileStore();
    const auth = useAuthStore();
    const setting = useSettingStore().leads;
    const alerts = useAlertsStore();
    profile.getAllProfiles();
    if (props.filterPhases != null) {
      profile.filters.profilePhases = ref(props.filterPhases);
    } else {
      profile.filters.profilePhases = [];
    }

    const leadStatuses = ref([]);
    Object.keys(LeadStateEnum).forEach(function (key) {
      const item = { ...LeadStateEnum[key] };
      if (item.subText) {
        item.text += " - " + item.subText;
      }
      item.value = key;
      leadStatuses.value.push(item);
    });
    const profilePhases = ref([]);
    Object.keys(ProfilePhaseEnum).forEach(function (key) {
      const item = ProfilePhaseEnum[key];
      item.value = key;
      profilePhases.value.push(item);
    });

    const segments = ref([]);
    Object.keys(SegmentEnum).forEach(function (key) {
      const item = { ...SegmentEnum[key] };
      item.value = key;
      segments.value.push(item);
    });

    const provinces = ref([]);
    Object.keys(ProvincesEnum).forEach(function (key) {
      const item = { ...ProvincesEnum[key] };
      item.value = key;
      provinces.value.push(item);
    });

    const sectors = ref([]);
    Object.keys(SectorEnum).forEach(function (key) {
      const item = { ...SectorEnum[key] };
      item.value = key;
      sectors.value.push(item);
    });

    return {
      profile,
      auth,
      setting,
      leadStatuses,
      profilePhases,
      segments,
      provinces,
      sectors,
      alerts,
    };
  },

  mounted() {
    if (this.setting.columns.length > 0) {
      this.columnsItems = this.setting.columns;
    } else {
      this.setting.columns = this.columnsItems;
    }
    this.columnsItems.sort((a, b) => {
      if (a.text > b.text) return 1;
      else if (a.text < b.text) return -1;

      return 0;
    });
  },

  created() {},

  data: () => ({
    csvFile: [],
    displayedProfilesNumber: 15,
    columnsItems: [
      { value: true, column: "leadStatus", text: "Status" },
      { value: true, column: "phase", text: "Fase" },
      { value: true, column: "employee", text: "Medewerker" },
      { value: true, column: "caller", text: "Beller" },
      { value: true, column: "name", text: "Naam" },
      { value: false, column: "kvk", text: "KvK" },
      { value: true, column: "cp_last_name", text: "CP Achternaam" },
      { value: true, column: "cp_initials", text: "CP Voorletters" },
      { value: false, column: "cp_gender", text: "CP Geslacht" },
      { value: true, column: "place", text: "Plaats" },
      { value: true, column: "province", text: "Provintie" },
      { value: true, column: "employees", text: "Werknemers" },
      { value: true, column: "tel", text: "Tel" },
      { value: true, column: "cel", text: "Cel" },
      { value: true, column: "email", text: "Mail" },
      { value: true, column: "last_contact", text: "Laatste Contact" },
      { value: true, column: "payroll", text: "Payroll" },
      { value: true, column: "planning", text: "Planning" },
      { value: false, column: "sector", text: "Sector" },
      { value: false, column: "segment", text: "Segment" },
      { value: true, column: "firstContact", text: "Eerste Contact" },
      { value: false, column: "relationPerc", text: "Won %" },
      { value: false, column: "noLocations", text: "Locaties" },
      { value: false, column: "notes", text: "Notes" },
      { value: false, column: "createdAt", text: "Aangemaakt" },
    ],
    showColumnSelection: false,
    csvUploadDialog: false,
    overlay: false,
    activeProfile: null,
    selectedProfile: null,
    selectedProfiles: [],
    appointmentType: "CALL_BACK",
    profileStatus: null,
    createProfileDialog: false,
    bulkActionsDialog: false,
    qrCodeDialog: false,
    searchTerms: {
      employeeCaller: [],
      leadState: [],
      accountManagers: [],
      extraPhases: [],
      text: "",
      segment: [],
      provinces: [],
      sector: null,
      dateRange: [],
    },
    showingProfiles: null,
    toEdit: false,
    presetRanges: [
      { label: "Today", range: [new Date(), new Date()] },
      {
        label: "This month",
        range: [startOfMonth(new Date()), endOfMonth(new Date())],
      },
      {
        label: "Last month",
        range: [
          startOfMonth(subMonths(new Date(), 1)),
          endOfMonth(subMonths(new Date(), 1)),
        ],
      },
      {
        label: "This year",
        range: [startOfYear(new Date()), endOfYear(new Date())],
      },
    ],
    contactInformation: {
      name: "",
      workPhone: "",
      email: "",
      homePhone: "",
      notes: "",
      fondness: "",
      profileId: "",
    },
    openAppointmentDialog: false,
  }),

  computed: {
    pageCount() {
      if (this.filteredProfiles) {
        const amount = this.filteredProfiles.length;
        return Math.ceil(amount / this.displayedProfilesNumber);
      }
      return 0;
    },
    visibleProfileNumbers() {
      return [
        {
          text: "100",
          value: 100,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "30",
          value: 30,
        },
        {
          text: "15",
          value: 15,
        },
        {
          text: "10",
          value: 10,
        },
      ];
    },
    filteredProfiles() {
      return this.profile.filteredProfiles;
    },
    customEmployees() {
      //* Bellers with a custom option to show unclaimed profiles
      let newFirstElement = {
        _id: "showUnclaimed",
        fullName: "Show Only Unclaimed",
      };
      let emps = [newFirstElement].concat(this.auth.activeEmployees);
      return emps;
    },
  },

  watch: {
    columnsItems(newItems) {
      this.setting.columns = newItems;
    },
    filteredProfiles(newItems, oldItems) {
      //* Everytime the lenght of the array chnages, page will be calclated
      if (newItems !== null && oldItems !== null) {
        if (newItems.length !== oldItems.length) {
          //* If the number of pages is smaller than
          //* the number of the current page
          //* the current page will become the last page
          let calculatedPages = Math.ceil(
            newItems.length / this.displayedProfilesNumber
          );
          if (calculatedPages < this.setting.pageNo) {
            if (calculatedPages === 0) {
              this.setting.pageNo = 1;
            } else {
              this.setting.pageNo = calculatedPages;
            }
          }
        }
      }
    },
  },

  methods: {
    rowClick(data) {
      this.activeProfile = data;
      this.toEdit = false;
      this.overlay = true;
    },
    editClick(data) {
      this.activeProfile = data;
      this.toEdit = true;
      this.overlay = true;
    },
    formatDate(date) {
      if (!date) return "";
      const cleanDate = new Date(date);
      const dateTime = cleanDate.toLocaleString("nl-NL").split(" ");
      const time = dateTime[1].split(":");
      return `${dateTime[0]} ${time[0]}:${time[1]}`;
    },
    async claimCaller(id) {
      const updatedProfile = {
        profile: id,
        update: { employeeCaller: this.auth.user._id },
      };
      await this.profile.updateProfile(updatedProfile);
      await this.profile.getAllProfiles();
    },
    async claimSales(id) {
      const updatedProfile = {
        profile: id,
        update: { employeeSales: this.auth.user._id },
      };
      await this.profile.updateProfile(updatedProfile);
      await this.profile.getAllProfiles();
    },
    async inTableEdit(data, id, field) {
      if (data === "CALL_BACK" || data === "NO_TIME_CALL") {
        this.selectedProfile = id;
        this.openAppointmentDialog = true;
        this.profileStatus = data;
      } else {
        const updatedProfile = {
          profile: id,
          update: { [field]: data },
        };
        await this.profile.updateProfile(updatedProfile);
        await this.profile.getAllProfiles();
      }
    },
    async submittedAppointment(data, field) {
      this.openAppointmentDialog = false;
      const updatedProfile = {
        profile: data.profileId,
        update: { [field]: data.status },
      };
      await this.profile.updateProfile(updatedProfile);
      await this.profile.getAllProfiles();
    },
    async inTableClearSelection(id, field) {
      const updatedProfile = {
        profile: id,
        update: { [field]: null },
      };
      await this.profile.updateProfile(updatedProfile);
      await this.profile.getAllProfiles();
    },
    formatState(state) {
      return LeadStateEnum[state];
    },
    formatPhase(phase) {
      return ProfilePhaseEnum[phase];
    },
    formatPhaseReason(phaseReason) {
      return ProfilePhaseReasonEnum[phaseReason];
    },
    formatFondness(fondness) {
      return ProfileFondnessEnum[fondness];
    },
    formatSector(sector) {
      if (sector) {
        return SectorEnum[sector].text;
      }
      return "";
    },
    formatSegment(segment) {
      if (segment) {
        return SegmentEnum[segment].text;
      }
      return "";
    },
    formatCurrentPayroll(payroll) {
      if (payroll) {
        return PayrollEnum[payroll].name;
      }
      return "";
    },
    formatCurrentPlan(plan) {
      if (plan) {
        return PlanningEnum[plan].name;
      }
      return "";
    },
    formatName(employee) {
      if (employee) {
        return `${employee.firstName}`;
      }
      return "";
    },
    formatProvince(province) {
      if (province) {
        if (ProvincesEnum[province]) {
          return ProvincesEnum[province].text;
        }
        return province;
      }
      return "";
    },
    formatProvinceByName(province) {
      switch (province) {
        case "Limburg":
          return "LI";
        case "Zeeland":
          return "ZE";
        case "Drenthe":
          return "DR";
        case "Flevoland":
          return "FL";
        case "Friesland":
          return "FR";
        case "Gelderland":
          return "GE";
        case "Groningen":
          return "GR";
        case "Noord-Brabant":
          return "NB";
        case "Noord-Holland":
          return "NH";
        case "Zuid-Holland":
          return "ZH";
        case "Utrecht":
          return "UT";
      }
    },
    updateColumnState(item) {
      item.value = !item.value;
    },
    columnVisible(colName) {
      return this.columnsItems.find((item) => item.column == colName).value;
    },
    displayQrCode(item) {
      this.contactInformation.workPhone = item.phoneNum;
      this.contactInformation.name = this.cleanUpName(item.name);
      this.contactInformation.email = item.email;
      this.contactInformation.homePhone = item.contactPerson.celNumber;
      this.contactInformation.notes = item.notes;
      this.contactInformation.fondness = item.fondness;
      this.contactInformation.profileId = item._id;
      this.qrCodeDialog = true;
    },
    cleanUpName(name) {
      let a = name.replace("&", "and");
      return a;
    },
    async fileUpload() {
      const input = this.csvFile[0];
      const reader = new FileReader();

      function csvToArray(str, delimiter = ";") {
        const headers = str.slice(0, str.indexOf("\n")).split(delimiter);
        const rows = str.slice(str.indexOf("\n") + 1).split("\n");
        const arr = rows.map(function (row) {
          const values = row.split(delimiter);
          const el = headers.reduce(function (object, header, index) {
            let val = values[index];
            if (val) {
              val = val.substring(1, val.length - 1);
            } else {
              val = "";
            }
            object[header] = val;
            return object;
          }, {});
          return el;
        });
        return arr;
      }
      reader.onload = (event) => {
        const text = event.target.result;
        const data = csvToArray(text);
        data.pop(); //Removes last element because for some reason the headers are on the last row again
        const profiles = data.map((x) => {
          let mapped = {
            kvk: x.DOSSIERNR.replaceAll('"', ""),
            phase: "LEAD",
            fondness: "NORMAL",
            sector: "HOSPITALITY",
            email: x.EMAIL.replaceAll('"', ""),
            name: x.FANAAM.replaceAll('"', ""),
            address: {
              street: x.STRAAT.replaceAll('"', ""),
              houseNo: x.HUISNR.replaceAll('"', ""),
              addition: x.HUISNRTOEV.replaceAll('"', ""),
              postcode: x.POSTCODE.replaceAll('"', ""),
              region: x.WOONPLAATS.replaceAll('"', ""),
              province: this.formatProvinceByName(
                x.PROVNAAM.replaceAll('"', "")
              ),
            },
            phoneNum: x.TELNR.replaceAll('"', ""),
            contactPerson: {
              celNumber: x.MOBIEL.replaceAll('"', ""),
              gender: x.CPSEXE.replaceAll('"', ""),
              initials: x.CPVOORL.replaceAll('"', ""),
              insertion: x.CPTUSSENV.replaceAll('"', ""),
              lastName: x.CPACHTER.replaceAll('"', ""),
            },
            employeesInService: {
              min: x.KLASSEWP.match(/\d+/g)?.[0],
              max: x.KLASSEWP.match(/\d+/g)?.[1],
            },
          };
          return mapped;
        });
        this.profile.bulkUploadProfiles(profiles).then(() => {
          this.profile.getAllProfiles();
          this.csvUploadDialog = false;
        });
      };

      reader.readAsText(input);
    },
    search() {
      this.searchTerms.profilePhases = this.filterPhases;
      this.profile.filters = this.searchTerms;
    },
    clear() {
      this.searchTerms.profilePhases = this.filterPhases;
      this.searchTerms.employeeCaller = [];
      this.searchTerms.accountManagers = [];
      this.searchTerms.extraPhases = [];
      this.searchTerms.leadState = [];
      this.searchTerms.text = "";
      this.searchTerms.segment = [];
      this.searchTerms.provinces = [];
      this.searchTerms.sector = null;
      this.searchTerms.dateRange = [];
      this.profile.filters = this.searchTerms;
    },
    clearStateFilter() {
      this.searchTerms.leadState = [];
      this.profile.filters = this.searchTerms;
    },
    clearCallerFilter() {
      this.searchTerms.employeeCaller = [];
      this.profile.filters = this.searchTerms;
    },
    clearSegmentFilter() {
      this.searchTerms.segment = [];
      this.profile.filters = this.searchTerms;
    },
    clearProvinceFilter() {
      this.searchTerms.provinces = [];
      this.profile.filters = this.searchTerms;
    },
    clearSectorFilter() {
      this.searchTerms.sector = null;
      this.profile.filters = this.searchTerms;
    },
    clearSearchFilter() {
      this.searchTerms.text = "";
      this.profile.filters = this.searchTerms;
    },
    clearAccManagersFilter() {
      this.searchTerms.accountManagers = [];
      this.profile.filters = this.searchTerms;
    },
    clearPhasesFilter() {
      this.searchTerms.extraPhases = [];
      this.profile.filters = this.searchTerms;
    },
    showBulkActionsDialog() {
      if (this.selectedProfiles.length <= 0)
        return this.showErrorMessage("Please select at least one profile.");
      this.bulkActionsDialog = true;
    },
    showErrorMessage(message) {
      this.alerts.error(message);
      setTimeout(() => {
        this.alerts.clear();
      }, 3000);
    },
    clearSelectedProfiles() {
      this.selectedProfiles = [];
    },
  },
};
</script>

<style>
.custom-text-field:deep(.v-field__field) {
  display: inline;
}
.custom-text-field:deep(.v-field--variant-contained) {
  border-radius: 8px;
}
.custom-card {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3) !important;
}
.custom-select:deep(.v-field--variant-contained) {
  border-radius: 8px;
}
.custom-overlay {
  right: 0px !important;
  left: auto !important;
}
.action-button {
  width: calc(var(--v-btn-height)) !important;
  height: calc(var(--v-btn-height)) !important;
}
</style>
