<template>
  <v-row justify="center">
    <v-card width="600">
      <v-card-title class="pa-4 pb-0">
        <span class="text-h5">Contact moment aanmaken</span>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-container class="pa-4">
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="formData.notes"
                label="Notities"
                hide-details
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                :items="leadStatuses"
                item-title="text"
                item-value="value"
                label="Lead Status"
                variant="solo"
                hide-details
                v-model="formData.newLeadState"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                :items="profilePhases"
                item-title="text"
                item-value="value"
                label="Profiel fase"
                variant="solo"
                hide-details
                v-model="formData.newPhase"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          size="large"
          variant="flat"
          @click="createContactMoment"
        >
          OPSLAAN
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script setup>
import { LeadStateEnum, ProfilePhaseEnum } from "../enums";
import { defineProps, ref, getCurrentInstance } from "vue";
import { useProfileStore } from "@/stores/profile";
import { useAuthStore } from "@/stores/auth";
const profile = useProfileStore();
const auth = useAuthStore();
const { emit } = getCurrentInstance();
const formData = ref({});
const props = defineProps({
  profileId: String,
});

const leadStatuses = ref([]);
Object.keys(LeadStateEnum).forEach(function (key) {
  const item = { ...LeadStateEnum[key] };
  if (item.subText) {
    item.text += " - " + item.subText;
  }
  item.value = key;
  leadStatuses.value.push(item);
});
const profilePhases = ref([]);
Object.keys(ProfilePhaseEnum).forEach(function (key) {
  const item = ProfilePhaseEnum[key];
  item.value = key;
  profilePhases.value.push(item);
});

function createContactMoment() {
  const now = new Date().toISOString();
  const contactProfile = {
    profile: props.profileId,
    contactMoment: {
      employee: auth.user._id,
      contactDate: now,
      notes: formData.value.notes,
      newPhase: formData.value.newPhase,
      newLeadState: formData.value.newLeadState,
    },
  };
  profile.createContactMoment(contactProfile);
  emit("close");
}
</script>

<style scoped></style>
