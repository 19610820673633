<template>
  <v-container fluid class="pa-0">
    <v-card class="pa-4" min-width="450px" max-width="850px" elevation="2">
      <v-row class="mb-2 mt-1" justify="center"
        ><h2>{{ selectedItem.title }}</h2>
        <v-chip
          class="mx-2 mt-1 mb-2"
          :color="
            selectedItem.status === 'CANCELLED'
              ? 'red'
              : selectedItem.status === 'ACTIVE'
              ? 'green'
              : 'blue'
          "
          text-color="white"
        >
          {{ appointmentStatus(selectedItem.status) }}
        </v-chip></v-row
      >
      <v-row v-if="selectedItem.employeeId !== null" class="">
        <v-col class="pb-1" md="1">
          <v-icon color="#403d39">mdi-account</v-icon>
        </v-col>
        <v-col class="pb-1">
          <div class="textDiv">
            {{ getEmployee(selectedItem.employeeId) }}
          </div>
        </v-col>
      </v-row>
      <v-row style="height: 65px" v-else>
        <v-col class="mt-3" md="1">
          <v-icon color="#403d39">mdi-account</v-icon>
        </v-col>
        <v-col class="mb-0 pb-0">
          <v-autocomplete
            v-model="employeeToAssign"
            :items="auth.activeEmployees"
            item-title="fullName"
            item-value="_id"
            density="compact"
            label="Employee"
          ></v-autocomplete>
        </v-col>
        <v-col md="3" class="mr-2">
          <v-btn color="red" variant="outlined" dark @click="assignEmployee()">
            save
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="">
        <v-col class="pb-1" md="1">
          <v-icon color="#403d39">mdi-briefcase</v-icon>
        </v-col>
        <v-col class="pb-1">
          <div class="textDiv">{{ getProfile(selectedItem.profileId) }}</div>
        </v-col>
      </v-row>
      <v-row class="">
        <v-col class="pb-1" md="1">
          <v-icon color="#403d39">mdi-card-text</v-icon>
        </v-col>
        <v-col class="pb-1">
          <div class="textDiv">
            {{ formatAppointmentType(selectedItem.type) }}
          </div>
        </v-col>
      </v-row>
      <v-row class="mb-1">
        <v-col class="pb-1" md="1">
          <v-icon color="#403d39">mdi-note</v-icon>
        </v-col>
        <v-col class="pb-1">
          <div class="textDivDescription">{{ selectedItem.description }}</div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="mt-1">
        <v-col md="1">
          <v-icon color="#403d39">mdi-calendar</v-icon>
        </v-col>
        <v-col>
          <div class="textDivDateTime">
            {{
              getDayOfWeek(selectedItem.day) +
              ", " +
              formatDate(selectedItem.dateTime)
            }}
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-0">
        <v-col md="1">
          <v-icon color="#403d39">mdi-calendar-clock</v-icon>
        </v-col>
        <v-col>
          <div class="textDivDateTime">
            {{
              formatTime(selectedItem.dateTime) +
              formatEndTime(selectedItem.endDateTime)
            }}
          </div>
        </v-col>
      </v-row>

      <v-row
        v-if="selectedItem.status === 'ACTIVE'"
        class="mb-0 mt-3"
        justify="center"
      >
        <v-btn color="red" variant="outlined" dark @click="cancelAppointment()">
          cancel appointment
        </v-btn>
      </v-row>
      <v-row
        v-if="
          selectedItem.status === 'CANCELLED' ||
          selectedItem.status === 'COMPLETED'
        "
        class="mb-0 mt-3"
        justify="center"
      >
        <v-btn
          color="green"
          variant="outlined"
          dark
          @click="activateAppointment()"
        >
          activate appointment
        </v-btn>
      </v-row>

      <v-row
        v-if="selectedItem.status === 'ACTIVE'"
        class="mb-0 mt-3"
        justify="center"
      >
        <v-btn
          color="green"
          variant="outlined"
          dark
          @click="completeAppointment()"
        >
          complete appointment
        </v-btn>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { useAppointmentStore } from "@/stores/appointment";
import { useAuthStore } from "@/stores/auth";
import { useProfileStore } from "@/stores/profile";
import {
  formatTime,
  formatDate,
  formatEndTime,
  getDayOfWeek,
} from "../utils/appointment_format_functions";
import { appointmentStatus } from "../utils/enum_helper";
import { AppointmentsTypeEnum } from "../enums";
export default {
  components: {},
  props: {
    selectedItem: Object,
  },
  setup() {
    const appointment = useAppointmentStore();
    const auth = useAuthStore();
    const profile = useProfileStore();
    return { appointment, auth, profile };
  },

  mounted() {},

  created() {},

  data: () => ({
    employeeToAssign: null,
  }),

  computed: {},

  watch: {},

  methods: {
    formatTime,
    formatDate,
    formatEndTime,
    getDayOfWeek,
    appointmentStatus,
    formatAppointmentType(type) {
      if (type) {
        if (AppointmentsTypeEnum[type]) {
          return AppointmentsTypeEnum[type].text;
        }
      }
      return "";
    },
    getEmployee(employee) {
      return employee.firstName + " " + employee.lastName;
    },
    getProfile(profile) {
      return profile.name;
    },
    async assignEmployee() {
      let appointmentData = {
        employeeId: this.employeeToAssign,
      };
      let updatedAppointment = {
        appointment: this.selectedItem._id,
        update: appointmentData,
      };
      await this.appointment.updateAppointment(updatedAppointment);
      this.$emit("close");
      await this.appointment.getAllAppointments();
    },
    async cancelAppointment() {
      let appointmentData = {
        status: "CANCELLED",
      };
      let updatedAppointment = {
        appointment: this.selectedItem._id,
        update: appointmentData,
      };
      await this.appointment.updateAppointment(updatedAppointment);
      this.$emit("close");
      await this.appointment.getAllAppointments();
    },
    async completeAppointment() {
      let appointmentData = {
        status: "COMPLETED",
      };
      let updatedAppointment = {
        appointment: this.selectedItem._id,
        update: appointmentData,
      };
      await this.appointment.updateAppointment(updatedAppointment);
      this.$emit("close");
      await this.appointment.getAllAppointments();
    },
    async activateAppointment() {
      let appointmentData = {
        status: "ACTIVE",
      };
      let updatedAppointment = {
        appointment: this.selectedItem._id,
        update: appointmentData,
      };
      await this.appointment.updateAppointment(updatedAppointment);
      this.$emit("close");
      await this.appointment.getAllAppointments();
    },
  },
};
</script>

<style scoped></style>
