<template>
  <v-dialog v-model="featureRequestDialog"
    ><FeatureRequestDialog @close="featureRequestDialog = false"
  /></v-dialog>

  <v-navigation-drawer
    class="custom-nav-drawer"
    permanent
    :rail="mini"
    @click.stop="mini = !mini"
    :style="{
      background:
        'linear-gradient(to right,rgba(218, 31, 132, 1),rgba(201, 28, 122, 1))',
    }"
  >
    <img
      v-if="!mini"
      :src="einsteinLogo"
      class="logoImage"
      style="width: 95%; height: auto; padding-left: 6px; padding-top: 6px"
    />
    <img
      v-else
      :src="einsteinMiniLogo"
      class="logoImage"
      style="width: 100%; height: auto"
    />

    <div class="imageSpacer" :style="spacerStyle" />
    <v-list
      bg-color="transparent"
      theme="dark"
      density="compact"
      style="margin-right: -1px"
      nav
    >
      <v-list-item
        v-for="(item, i) in navItems"
        :key="i"
        :value="item"
        @click="goTo(item.link)"
        @click.stop="mini = false"
        active-class="custom-active-class"
        rounded="lg"
        :active="isActive(item.name)"
      >
        <template v-slot:prepend>
          <v-icon
            v-if="isActive(item.name)"
            color="rgba(255,255,255,1)"
            :icon="item.icon"
          ></v-icon>
          <v-icon
            v-else
            color="rgba(255,255,255,.8)"
            :icon="item.icon"
          ></v-icon>
        </template>
        <v-list-item-title
          :style="activeTextStyle()"
          v-text="item.text"
        ></v-list-item-title>
      </v-list-item>
    </v-list>

    <v-list
      class="fixedBottom"
      bg-color="transparent"
      theme="dark"
      density="compact"
      style="margin-right: -1px"
      nav
    >
      <v-list-item
        @click.stop="featureRequestDialog = true"
        :style="activeTextStyle()"
        prepend-icon="mdi-bug"
        title="Bug/Idee Melder"
      ></v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script setup>
//import { useAuthStore } from "@/stores/auth";
import { ref, watch, defineProps, reactive } from "vue";
import { useRoute } from "vue-router";
import router from "../router";
import einsteinLogo from "@/assets/mr-einstein-logo.png";
import einsteinMiniLogo from "@/assets/mr-ein-small.png";
import FeatureRequestDialog from "./FeatureRequestDialog.vue";
const props = defineProps({
  appBarHeight: Number,
});
const spacerStyle = reactive({
  height: props.appBarHeight + "px",
});
const companyLogoWidth = ref(0);
const logo = new Image();
const featureRequestDialog = ref(false);
let mini = ref(true);
logo.src = einsteinLogo;
logo.onload = () => {
  companyLogoWidth.value = (logo.width / logo.height) * props.appBarHeight - 40;
};
//const auth = useAuthStore();
const route = useRoute();
const activeItem = ref(null);
const navItems = ref([
  {
    text: "Home",
    icon: "mdi-home-city",
    link: "",
    name: "home",
  },
  {
    text: "Bel Lijst",
    icon: "mdi-phone",
    link: "leads",
    name: "leads",
  },
  {
    text: "Prospects",
    icon: "mdi-basket-outline",
    link: "prospects",
    name: "prospects",
  },
  {
    text: "Klanten",
    icon: "mdi-briefcase-account-outline",
    link: "customers",
    name: "customers",
  },
  {
    text: "Profiles",
    icon: "mdi-account-group-outline",
    link: "profiles",
    name: "profiles",
  },
  {
    text: "Medewerkers",
    icon: "mdi-account-tie",
    link: "employees",
    name: "employees",
  },
  {
    text: "Kalender",
    icon: "mdi-calendar-clock",
    link: "appointments",
    name: "appointments",
  },
  {
    text: "Statistics",
    icon: "mdi-information-variant",
    link: "statistics",
    name: "statistics",
  },
]);
function goTo(page) {
  router.push("/" + page);
}

function isActive(name) {
  if (name == activeItem.value) {
    return true;
  }
  return false;
}

function activeTextStyle() {
  return { color: "rgba(255, 255, 255, .9)", fontSize: "15px" };
}

watch(
  () => route.name,
  () => {
    activeItem.value = route.name;
  }
);
</script>
<style scoped>
.custom-active-class :deep(.v-list-item__overlay) {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.4),
    rgba(218, 31, 132, 1)
  );
  opacity: 1;
}
.bg-transparent {
  color: rgba(0, 0, 0, 0) !important;
}
.logoImage {
  top: 0;
  left: 0;
  position: fixed;
}
.v-list-item:hover {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.3),
    rgba(218, 31, 132, 0.3)
  );
}
.fixedBottom {
  position: fixed !important;
  bottom: 0 !important;
  width: 100%;
}
</style>
