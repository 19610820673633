import { defineStore } from "pinia";
import { authHeader } from "../utils/auth-header";
import { handleResponse } from "../utils/api_functions";
import config from "../../config";

export const useEmployeeStore = defineStore("employees", {
  state: () => ({}),

  actions: {
    async uploadProfilePic({ fileToUpload, uploadData }) {
      try {
        let alteredHeader = authHeader();
        delete alteredHeader["Content-Type"];
        const formData = new FormData();
        formData.append("file", fileToUpload);
        formData.append("data", JSON.stringify(uploadData));
        const requestOptions = {
          method: "PUT",
          headers: alteredHeader,
          body: formData,
        };
        return await fetch(
          `${config.apiUrl}/tenant/employees-file-upload`,
          requestOptions
        )
          .then(handleResponse)
          .then(async (res) => {
            return res;
          });
      } catch (error) {
        console.log(error);
      }
    },
    async updateEmployee(employeeData) {
      try {
        const requestOptions = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(employeeData),
        };
        return await fetch(
          `${config.apiUrl}/tenant/employee/update`,
          requestOptions
        )
          .then(handleResponse)
          .then(async () => {
            return;
          });
      } catch (error) {
        console.log(error);
      }
    },
    async deleteEmployee(id) {
      try {
        const requestOptions = {
          method: "DELETE",
          headers: authHeader(),
        };
        return await fetch(
          `${config.apiUrl}/tenant/employee/delete/${id}`,
          requestOptions
        )
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        console.error(error);
      }
    },
  },

  getters: {},
});
