const LeadStateEnum = Object.freeze({
  APPOINTMENT: { text: "Afspraak", color: "green" },
  NO_INTEREST_STAFF: {
    text: "Geen intresse",
    subText: "Geen/weinig personeel",
    color: "red",
  },
  NO_INTEREST_LOYAL: {
    text: "Geen intresse",
    subText: "Loyaal huidige partners",
    color: "red",
  },
  NO_TIME_CALL: {
    text: "Geen tijd",
    subText: "Terug bellen",
    color: "orange",
  },
  NO_TIME_NO_CALL: {
    text: "Geen tijd",
    subText: "Niet terug bellen",
    color: "red",
  },
  CALL_BACK: { text: "Terugbelverzoek", color: "orange" },
  CONTACT_PERSON_NOT_AVAIL: {
    text: "Contactpersoon niet aanwezig",
    color: "orange",
  },
  NO_ANWSER: { text: "Geen gehoor", color: "red" },
  MAIL_INFO: { text: "Informatie mailen", color: "orange" },
  NO_INTEREST_GENERAL: { text: "Geen intresse algemeen", color: "red" },
  LONG_TERM: { text: "Langelead termijn", color: "red" },
  SOLD: { text: "Failliet / verkocht / nieuwe eigenaar", color: "red" },
});

export default LeadStateEnum;
