<template>
  <v-row justify="center">
    <v-card width="800" class="pa-4 overflow-y-auto" max-height="80vh">
      <v-card-title class="pa-4 pb-0">
        <span class="text-h5">Profiel aanmaken</span>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-form ref="creationForm" class="custom-form" v-model="valid">
          <v-container>
            <v-row class="mb-2">
              <v-col cols="12" sm="6">
                <v-text-field
                  :rules="rules.name"
                  v-model="formData.name"
                  label="Naam"
                  required
                  :hide-details="hideDetails"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" v-if="!editMode">
                <v-select
                  :items="profilePhases"
                  item-title="text"
                  item-value="value"
                  label="Profiel fase"
                  :rules="rules.phase"
                  v-model="formData.phase"
                  required
                  :hide-details="hideDetails"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="formData.kvk"
                  hide-details
                  label="KvK"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" v-if="!editMode">
                <v-select
                  :items="leadStatuses"
                  item-title="text"
                  item-value="value"
                  label="Lead Status"
                  v-model="formData.leadState"
                  hide-details
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="formData.email"
                  hide-details
                  label="Mail"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="formData.phoneNum"
                  hide-details
                  label="Tel"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="formData.visits"
                  hide-details
                  label="Aantal bezoeken"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="formData.employeesInService.min"
                  hide-details
                  label="Werkneemers Min."
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="formData.employeesInService.max"
                  hide-details
                  label="Werkneemers Max."
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="formData.noLocations"
                  hide-details
                  label="Aantal Locaties"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="formData.relationPerc"
                  hide-details
                  label="Winst %"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  :items="sectors"
                  item-title="text"
                  item-value="value"
                  label="Sector"
                  v-model="formData.sector"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  :items="segments"
                  item-title="text"
                  item-value="value"
                  label="Segment"
                  v-model="formData.segment"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  :items="fondnesses"
                  item-title="text"
                  item-value="value"
                  label="Soort Profiel"
                  v-model="formData.fondness"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12">
                <v-row align="center">
                  <v-col cols="12" sm="4">
                    <v-checkbox
                      v-model="formData.usingPayroll"
                      label="Gebruikt Payroll"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-select
                      :items="payrolls"
                      item-title="name"
                      item-value="value"
                      label="Payroll"
                      v-model="formData.currentPayroll"
                      hide-details
                      :disabled="!formData.usingPayroll"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="12">
                <v-row align="center">
                  <v-col cols="12" sm="4">
                    <v-checkbox
                      v-model="formData.usingPlan"
                      label="Gebruikt Planning"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-select
                      :items="plannings"
                      item-title="name"
                      item-value="value"
                      label="Planning"
                      v-model="formData.currentPlan"
                      hide-details
                      :disabled="!formData.usingPlan"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="pt-4 ml-8 mr-8" />
            <span class="text-h6">Contact Persoon</span>
            <v-row class="pt-1 mb-2">
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="formData.contactPerson.initials"
                  hide-details
                  label="Voornaam"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="formData.contactPerson.insertion"
                  hide-details
                  label="Tussenv."
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="formData.contactPerson.lastName"
                  hide-details
                  label="Achternaam"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  :items="['M', 'V']"
                  label="Geslacht"
                  v-model="formData.contactPerson.gender"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="formData.contactPerson.celNumber"
                  hide-details
                  label="Telefoonnummer"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider class="pt-4 ml-8 mr-8" />
            <span class="text-h6">Address</span>
            <v-row class="pt-1 mb-2">
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="formData.address.street"
                  hide-details
                  label="Straat"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="formData.address.houseNo"
                  hide-details
                  label="No."
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="formData.address.addition"
                  hide-details
                  label="Toev."
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="formData.address.postcode"
                  hide-details
                  label="Postcode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="formData.address.region"
                  hide-details
                  label="Stad"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="formData.address.province"
                  :items="provinces"
                  item-title="text"
                  item-value="value"
                  label="Provincie"
                  required
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
            <v-divider class="pt-4 ml-8 mr-8" />
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="formData.notes"
                  label="Notities"
                  hide-details
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          size="large"
          variant="flat"
          @click="createProfile"
        >
          OPSLAAN
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script setup>
import {
  LeadStateEnum,
  ProfilePhaseEnum,
  SectorEnum,
  SegmentEnum,
  PayrollEnum,
  PlanningEnum,
  ProfileFondnessEnum,
  ProvincesEnum,
} from "../enums";
import { ref, getCurrentInstance, defineProps, computed } from "vue";
import { useProfileStore } from "@/stores/profile";
//import { useAuthStore } from "@/stores/auth";
const profile = useProfileStore();
// const auth = useAuthStore();
const { emit } = getCurrentInstance();
const emptyData = ref({
  contactPerson: {},
  employeesInService: {},
  address: {},
  fondness: "NORMAL",
});
const props = defineProps({
  data: Object,
  editMode: Boolean,
});
const formData = computed(() => {
  let vals = {};
  if (props.data) {
    vals = props.data;
    if (vals.employeesInService == null) {
      vals.employeesInService = {};
    }
    if (vals.contactPerson == null) {
      vals.contactPerson = {};
    }
    if (vals.address == null) {
      vals.address = {};
    }
  } else {
    vals = emptyData.value;
  }
  return vals;
});
const creationForm = ref(null);
const valid = ref(null);
const hideDetails = ref(true);
const rules = {
  name: [(v) => !!v || "Naam is verplicht"],
  phase: [(v) => !!v || "Fase is verplicht"],
};

// ENUMS >> Array lists
const leadStatuses = ref([]);
Object.keys(LeadStateEnum).forEach(function (key) {
  const item = { ...LeadStateEnum[key] };
  if (item.subText) {
    item.text += " - " + item.subText;
  }
  item.value = key;
  leadStatuses.value.push(item);
});
const profilePhases = ref([]);
Object.keys(ProfilePhaseEnum).forEach(function (key) {
  const item = ProfilePhaseEnum[key];
  item.value = key;
  profilePhases.value.push(item);
});
const sectors = ref([]);
Object.keys(SectorEnum).forEach(function (key) {
  const item = SectorEnum[key];
  item.value = key;
  sectors.value.push(item);
});
const segments = ref([]);
Object.keys(SegmentEnum).forEach(function (key) {
  const item = SegmentEnum[key];
  item.value = key;
  segments.value.push(item);
});
const payrolls = ref([]);
Object.keys(PayrollEnum).forEach(function (key) {
  const item = PayrollEnum[key];
  item.value = key;
  payrolls.value.push(item);
});
const plannings = ref([]);
Object.keys(PlanningEnum).forEach(function (key) {
  const item = PlanningEnum[key];
  item.value = key;
  plannings.value.push(item);
});
const fondnesses = ref([]);
Object.keys(ProfileFondnessEnum).forEach(function (key) {
  const item = ProfileFondnessEnum[key];
  item.value = key;
  fondnesses.value.push(item);
});
const provinces = ref([]);
Object.keys(ProvincesEnum).forEach(function (key) {
  const item = { ...ProvincesEnum[key] };
  item.value = key;
  provinces.value.push(item);
});

// Functions
async function createProfile() {
  await creationForm?.value.validate();
  if (!valid.value) {
    hideDetails.value = false;
  } else {
    hideDetails.value = true;
    const profileData = formData.value;
    if (!props.editMode) {
      const now = new Date().toISOString();
      profileData.createdAt = now;
      profile.createProfile(profileData);
    } else {
      const updatedProfile = {
        profile: profileData._id,
        update: profileData,
      };
      await profile.updateProfile(updatedProfile);
    }
    emit("close");
  }
}
</script>

<style scoped>
.custom-form:deep(.v-input__details) {
  margin: 0px;
}
</style>
