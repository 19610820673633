const SegmentEnum = Object.freeze({
  BAR: { text: "Natte horeca" },
  RESTAURANT: { text: "Restaurant" },
  FASTFOOD: { text: "Fastfood" },
  RECREATION: { text: "Recreatie" },
  HOTEL: { text: "Hotel" },
  OTHER: { text: "Anders" },
});

export default SegmentEnum;
