<template>
  <v-row justify="center">
    <v-card width="800" class="pa-4 overflow-y-auto" max-height="80vh">
      <v-card-title class="pa-4 pb-0">
        <span class="text-h5"
          >Deze bulk actie word uitgevoerd op alle geselecteerde rijen</span
        >
      </v-card-title>
      <v-card-text class="pa-0">
        <v-form ref="creationForm" class="custom-form">
          <v-container>
            <v-row class="mb-2">
              <!-- Profile phases -->
              <v-col cols="12" sm="6">
                <v-select
                  :items="profilePhases"
                  item-title="text"
                  item-value="value"
                  label="Profiel fase"
                  v-model="selectedPhase"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          size="large"
          variant="flat"
          @click="setSelectedPhase"
        >
          OPSLAAN
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script setup>
import { ProfilePhaseEnum } from "../enums";

import { ref, defineProps } from "vue";
import { useProfileStore } from "@/stores/profile";

const profile = useProfileStore();

const emits = defineEmits(["close", "submit"]);

const props = defineProps({
  selectedProfiles: Object,
});

const selectedPhase = ref("");

// ENUMS >> Array lists
const profilePhases = ref([]);
Object.keys(ProfilePhaseEnum).forEach(function (key) {
  const item = ProfilePhaseEnum[key];
  item.value = key;
  profilePhases.value.push(item);
});

async function setSelectedPhase() {
  for (let selectedProfile of props.selectedProfiles) {
    const updatedProfile = {
      profile: selectedProfile._id,
      update: { phase: selectedPhase.value },
    };
    await profile.updateProfile(updatedProfile);
  }
  await profile.getAllProfiles();
  await emits("close");
}
</script>

<style scoped></style>
