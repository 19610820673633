<template>
  <v-row justify="center">
    <v-card width="500" class="pa-4 overflow-y-auto" max-height="80vh">
      <v-card-title>
        <span class="text-h4">Wachtwoord aanpassen</span>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-form ref="passForm" class="custom-form" v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  :rules="[rules.required, rules.min]"
                  v-model="formData.pass"
                  label="Nieuw wachtwoord"
                  required
                  hint="Ten minste 8 tekens"
                  counter
                  :type="show1 ? 'text' : 'password'"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  autocomplete="new-password"
                  @click:append="show1 = !show1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  :rules="[rules.match]"
                  v-model="formData.repeatpass"
                  label="Herhaal wachtwoord"
                  required
                  hint="Ten minste 8 tekens"
                  counter
                  :type="show2 ? 'text' : 'password'"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  autocomplete="new-password"
                  @click:append="show2 = !show2"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="">
        <v-spacer></v-spacer>
        <v-btn color="success" size="large" variant="flat" @click="changePass">
          VERANDEREN
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import { useAuthStore } from "@/stores/auth";
const auth = useAuthStore();
const emits = defineEmits(["close"]);
const formData = ref({});
const passForm = ref(null);
const valid = ref(null);
const show1 = ref(false);
const show2 = ref(false);

const rules = {
  required: (v) => !!v || "Verplicht",
  min: (v) => v.length >= 8 || "Min 8 karakters",
  match: (v) =>
    v == formData.value.pass || `De wachtwoorden komen niet overeen`,
};

// Functions
async function changePass() {
  await passForm?.value.validate();
  if (valid.value) {
    const newPassword = {
      pass: formData.value.pass,
    };
    await auth.updatePassword(newPassword).then(() => {
      emits("close");
    });
  }
}
</script>

<style scoped>
.custom-form:deep(.v-input__details) {
  margin: 0px;
}
</style>
