import { defineStore } from "pinia";
//import config from "../../config";

export const useSettingStore = defineStore("settings", {
  state: () => ({
    leads: { pageNo: 1, columns: [] },
  }),

  actions: {},

  getters: {},
});
