import { defineStore } from "pinia";
import { useProfileStore } from "@/stores/profile";
import config from "../../config";

export const useSocketStore = defineStore("socket", {
  state: () => ({
    connection: null,
  }),

  actions: {
    connect() {
      const token = localStorage.getItem("token").split(" ")[1];
      this.connection = new WebSocket(`${config.socketUrl}socket/${token}`);
      this.connection.onmessage = function (event) {
        const profile = useProfileStore();
        const message = JSON.parse(event.data);
        if (message.type) {
          switch (message.type) {
            case "CONNECTED":
              console.log("🟢 Socket connected");
              break;
            case "CALLER_ASSIGN":
              profile.recieveProfileChange(message.data.profile);
              break;
            default:
              console.log("Unhandled socket message", JSON.parse(event.data));
          }
        }
      };
    },
  },

  getters: {},
});
