<template>
  <v-row justify="center">
    <v-card width="800" class="pa-4 overflow-y-auto" max-height="80vh">
      <v-card-title class="pa-4 pb-0">
        <span class="text-h5">Profiel aanmaken</span>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-form ref="creationForm" class="custom-form" v-model="valid">
          <v-container>
            <v-row class="mb-2">
              <v-col cols="12" sm="6">
                <v-text-field
                  :rules="rules.required"
                  v-model="formData.firstName"
                  label="Voornaam"
                  required
                  :hide-details="hideDetails"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  :rules="rules.required"
                  v-model="formData.lastName"
                  label="Achternaam"
                  required
                  :hide-details="hideDetails"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  :rules="rules.required"
                  v-model="formData.email"
                  label="Email"
                  required
                  :hide-details="hideDetails"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  :rules="rules.required"
                  v-model="formData.cel"
                  label="Telefoon"
                  required
                  :hide-details="hideDetails"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  :items="employeeRoles"
                  item-title="name"
                  item-value="value"
                  label="Rol"
                  :rules="rules.required"
                  v-model="formData.role"
                  required
                  :hide-details="hideDetails"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          size="large"
          variant="flat"
          @click="createEmployee"
        >
          AANMAKEN
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script setup>
import { EmployeeRoleEnum } from "../enums";
import { ref, defineEmits } from "vue";
import { useAuthStore } from "@/stores/auth";
//import { useAuthStore } from "@/stores/auth";
const auth = useAuthStore();
// const auth = useAuthStore();
const emits = defineEmits(["close"]);
const formData = ref({});
const creationForm = ref(null);
const valid = ref(null);
const hideDetails = ref(true);
const rules = {
  required: [(v) => !!v || "Verplicht"],
};

// ENUMS >> Array lists
const employeeRoles = ref([]);
Object.keys(EmployeeRoleEnum).forEach(function (key) {
  const item = { ...EmployeeRoleEnum[key] };
  item.value = key;
  employeeRoles.value.push(item);
});

// Functions
async function createEmployee() {
  await creationForm?.value.validate();
  if (!valid.value) {
    hideDetails.value = false;
  } else {
    hideDetails.value = true;
    const employeeData = formData.value;
    await auth.register(employeeData).then((tempPass) => {
      tempPass;
      emits("close", tempPass);
    });
  }
}
</script>

<style scoped>
.custom-form:deep(.v-input__details) {
  margin: 0px;
}
</style>
