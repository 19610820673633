// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

const customLight = {
  dark: false,
  colors: {
    background: "#FFFFFF",
    surface: "#FFFFFF",
    "surface-variant": "#424242",
    "on-surface-variant": "#EEEEEE",
    primary: "#2196f3",
    "primary-darken-1": "#0069c0",
    secondary: "#da1f84",
    "secondary-darken-1": "#a30057",
    error: "#B00020",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
    "on-background": "#000",
    "on-surface": "#000",
    "on-primary": "#000000",
    "on-primary-darken-1": "#fff",
    "on-secondary": "#ffffff",
    "on-secondary-darken-1": "#fff",
    "on-error": "#fff",
    "on-info": "#fff",
    "on-success": "#fff",
    "on-warning": "#fff",
  },
};
const customDark = {
  dark: true,
  colors: {
    background: "#FFFFFF",
    surface: "#FFFFFF",
    "surface-variant": "#424242",
    "on-surface-variant": "#EEEEEE",
    primary: "#6200EE",
    "primary-darken-1": "#3700B3",
    secondary: "#03DAC6",
    "secondary-darken-1": "#018786",
    error: "#B00020",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
    "on-background": "#000",
    "on-surface": "#000",
    "on-primary": "#fff",
    "on-primary-darken-1": "#fff",
    "on-secondary": "#000",
    "on-secondary-darken-1": "#fff",
    "on-error": "#fff",
    "on-info": "#fff",
    "on-success": "#fff",
    "on-warning": "#fff",
  },
};

export default createVuetify({
  theme: {
    defaultTheme: "customLight",
    themes: {
      customLight,
      customDark,
    },
  },
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
