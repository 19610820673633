const ProvincesEnum = Object.freeze({
  DR: { text: "Drenthe" },
  FL: { text: "Flevoland" },
  FR: { text: "Friesland" },
  GE: { text: "Gelderland" },
  GR: { text: "Groningen" },
  LI: { text: "Limburg" },
  NB: { text: "Noord-Brabant" },
  NH: { text: "Noord-Holland" },
  OV: { text: "Overijssel" },
  ZH: { text: "Zuid-Holland" },
  UT: { text: "Utrecht" },
  ZE: { text: "Zeeland" },
});

export default ProvincesEnum;
