import { defineStore } from "pinia";
import config from "../../config";
import { authHeader } from "../utils/auth-header";
import { handleResponse } from "../utils/api_functions";

export const useProfileStore = defineStore("profile", {
  state: () => ({
    profiles: null,
    filters: {
      employeeCaller: [],
      leadState: [],
      accountManagers: [],
      extraPhases: [],
      text: "",
      segment: [],
      provinces: [],
      sector: null,
      dateRange: [],
      profilePhases: [],
    },
  }),
  getters: {
    filteredProfiles: (state) => {
      if (state.profiles !== null) {
        return state.profiles.filter((profile) => {
          let callerFilter = true;
          let stateFilter = true;
          let searchFilter = true;
          let segmentsFilter = true;
          let provincesFilter = true;
          let sectorFilter = true;
          let createdAtFilter = true;
          let phaseFilter = true;
          let accountManagersFilter = true;

          //* Employee Caller
          if (state.filters.employeeCaller.length !== 0) {
            callerFilter =
              state.filters.employeeCaller.includes(profile.employeeCaller) ||
              (state.filters.employeeCaller.includes("showUnclaimed") &&
                profile.employeeCaller == null);
          }

          //* Account Manager
          if (state.filters.accountManagers.length !== 0) {
            accountManagersFilter = state.filters.accountManagers.includes(
              profile.employeeSales
            );
          }

          //* Lead State
          if (state.filters.leadState.length !== 0) {
            stateFilter = state.filters.leadState.includes(profile.leadState);
          }

          //* Search
          if (state.filters.text != "") {
            searchFilter = JSON.stringify(profile)
              .toLowerCase()
              .includes(state.filters.text.toLowerCase());
          }

          //* Segment
          if (state.filters.segment.length !== 0) {
            segmentsFilter = state.filters.segment.includes(profile.segment);
          }

          //* Province
          if (state.filters.provinces.length !== 0) {
            provincesFilter = state.filters.provinces.includes(
              profile.address.province
            );
          }

          //* Sector
          if (state.filters.sector !== null) {
            sectorFilter = state.filters.sector === profile.sector;
          }

          //* createdAt
          if (
            state.filters.dateRange != null &&
            state.filters.dateRange.length > 1
          ) {
            let profileCreatedDate = new Date(profile.createdAt)
              .toISOString()
              .substring(0, 10);
            let startDate = new Date(state.filters.dateRange[0])
              .toISOString()
              .substring(0, 10);
            let endDate = new Date(state.filters.dateRange[1])
              .toISOString()
              .substring(0, 10);

            createdAtFilter =
              profileCreatedDate >= startDate && profileCreatedDate <= endDate;
          }
          //* Profile Phase + Extra Selectable Filter
          if (
            state.filters.profilePhases != null &&
            state.filters.profilePhases.length >= 1
          ) {
            if (state.filters.extraPhases.length === 0) {
              phaseFilter = state.filters.profilePhases.includes(profile.phase);
            } else {
              phaseFilter =
                state.filters.profilePhases.includes(profile.phase) &&
                state.filters.extraPhases.includes(profile.phase);
            }
          } else if (state.filters.extraPhases.length !== 0) {
            phaseFilter = state.filters.extraPhases.includes(profile.phase);
          }

          return (
            callerFilter &&
            stateFilter &&
            searchFilter &&
            segmentsFilter &&
            provincesFilter &&
            sectorFilter &&
            createdAtFilter &&
            phaseFilter &&
            accountManagersFilter
          );
        });
      }
      return state.profiles;
    },
  },
  actions: {
    async getAllProfiles() {
      try {
        const requestOptions = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/tenant/profiles`, requestOptions)
          .then(handleResponse)
          .then((res) => {
            this.profiles = res;
            return res;
          });
      } catch (error) {
        console.error(error);
      }
    },

    async bulkUploadProfiles(profiles) {
      try {
        const requestOptions = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(profiles),
        };
        return await fetch(
          `${config.apiUrl}/tenant/profiles/bulk`,
          requestOptions
        )
          .then(handleResponse)
          .then(() => {});
      } catch (error) {
        console.error(error);
      }
    },

    async updateProfile(updatedProfile) {
      try {
        const requestOptions = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(updatedProfile),
        };
        return await fetch(
          `${config.apiUrl}/tenant/profiles/update`,
          requestOptions
        )
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        console.error(error);
      }
    },

    async createProfile(profileData) {
      try {
        const requestOptions = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(profileData),
        };
        return await fetch(`${config.apiUrl}/tenant/profiles`, requestOptions)
          .then(handleResponse)
          .then((res) => {
            this.profiles.push(res);
            return res;
          });
      } catch (error) {
        console.error(error);
      }
    },

    async createContactMoment(contactProfile) {
      try {
        const requestOptions = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(contactProfile),
        };
        return await fetch(
          `${config.apiUrl}/tenant/profiles/createContactMoment`,
          requestOptions
        )
          .then(handleResponse)
          .then((res) => {
            this.updateProfileField(res, "contactMoments");
            this.updateProfileField(res, "leadState");
            return res;
          });
      } catch (error) {
        console.error(error);
      }
    },

    async deleteProfile(profileId) {
      try {
        const requestOptions = {
          method: "DELETE",
          headers: authHeader(),
        };
        return await fetch(
          `${config.apiUrl}/tenant/profile/${profileId}`,
          requestOptions
        )
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        console.error(error);
      }
    },

    recieveProfileChange(updatedProfile) {
      if (this.profiles != null) {
        if (this.profiles.length > 0) {
          const index = this.profiles.findIndex(
            (prof) => prof._id == updatedProfile._id
          );
          if (index >= 0) {
            this.profiles[index] = updatedProfile;
          }
        }
      }
    },

    updateProfileField(updatedProfile, field) {
      if (this.profiles.length > 0) {
        const index = this.profiles.findIndex(
          (prof) => prof._id == updatedProfile._id
        );
        if (index >= 0) {
          this.profiles[index][field] = updatedProfile[field];
        }
      }
    },
    //! Temporary Function to FIX Province issue
    //! Remove after use
    async updateOutdatedData() {
      try {
        const requestOptions = {
          method: "PUT",
          headers: authHeader(),
        };
        return await fetch(
          `${config.apiUrl}/tenant/profiles/updatedAddress`,
          requestOptions
        )
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        console.error(error);
      }
    },
  },
});
