<template>
  <v-container fluid class="pa-0">
    <v-dialog v-model="showAppointmentInfo"
      ><AppointmentOverview
        :selectedItem="selectedItem"
        @close="showAppointmentInfo = false"
    /></v-dialog>
    <div v-if="this.type === 1">
      <h4 v-if="myAppointments.length == 0">No Appointments Found</h4>
      <v-card elevation="0" v-for="item in myAppointments" :key="item.date">
        <template v-slot:title>{{
          getDayOfWeek(item.date) + " " + item.date
        }}</template>
        <v-card
          elevation="2"
          class="ma-2 ml-8"
          :class="
            appointment.type === 'APPOINTMENT'
              ? 'appointmentCard'
              : appointment.type === 'CALL_BACK'
              ? 'callbackCard'
              : 'reminderCard'
          "
          v-for="appointment in item.appointments"
          :key="appointment"
          @click="appointmentClick(appointment)"
        >
          <v-card-title class="pb-2"
            >{{ appointment.title
            }}<v-chip
              class="mx-2 mt-1 mb-2"
              :color="
                appointment.status === 'CANCELLED'
                  ? 'red'
                  : appointment.status === 'ACTIVE'
                  ? 'green'
                  : 'blue'
              "
              text-color="white"
            >
              {{ appointmentStatus(appointment.status) }}
            </v-chip></v-card-title
          >
          <v-card-text class="ml-6">
            {{ appointment.description }}
          </v-card-text>
          <div class="mb-2 mr-4 appointmentCardTime">
            {{
              formatTime(appointment.dateTime) +
              formatEndTime(appointment.endDateTime)
            }}
          </div>
        </v-card>
      </v-card>
    </div>
    <div v-if="this.type === 2">
      <div
        v-if="myAppointments.length == 0"
        class="pa-2"
        style="text-align: center"
      >
        <h4>No Appointments Found</h4>
      </div>
      <v-card elevation="0" v-for="item in myAppointments" :key="item.date">
        <v-card-title class="dayTitlePopUp">{{
          getDayOfWeek(item.date) + " " + item.date
        }}</v-card-title>
        <v-card
          elevation="2"
          class="my-2 ml-6"
          :class="
            appointment.type === 'APPOINTMENT'
              ? 'appointmentCardPopUp'
              : appointment.type === 'CALL_BACK'
              ? 'callbackCardPopUp'
              : 'reminderCardPopUp'
          "
          v-for="appointment in item.appointments"
          :key="appointment"
          @click="appointmentClick(appointment)"
        >
          <v-card-title class="pb-0 appointmentTitlePopUp"
            >{{ appointment.title
            }}<v-chip
              class="mx-2 mt-1 mb-2"
              :color="
                appointment.status === 'CANCELLED'
                  ? 'red'
                  : appointment.status === 'ACTIVE'
                  ? 'green'
                  : 'blue'
              "
              text-color="white"
            >
              {{ appointmentStatus(appointment.status) }}
            </v-chip></v-card-title
          >
          <div class="mb-2 mr-4 appointmentCardTimePopUp">
            {{
              formatTime(appointment.dateTime) +
              formatEndTime(appointment.endDateTime)
            }}
          </div>
        </v-card>
      </v-card>
    </div>
  </v-container>
</template>
<script>
import { useAppointmentStore } from "@/stores/appointment";
import AppointmentOverview from "../components/AppointmentOverview";
import { appointmentStatus } from "../utils/enum_helper";
import {
  formatDateTime,
  formatTime,
  formatDate,
  formatEndTime,
  getDayOfWeek,
} from "../utils/appointment_format_functions";
export default {
  components: {
    AppointmentOverview,
  },
  props: {
    type: Number, //1 = Kalendar Page | 2 = Profile Overlay
  },
  setup() {
    const appointment = useAppointmentStore();
    appointment.getAllAppointments();
    return { appointment };
  },

  mounted() {},

  created() {},

  data: () => ({
    selectedItem: {},
    showAppointmentInfo: false,
  }),

  computed: {
    myAppointments() {
      return this.appointment.myAppointments;
    },
    allAppointments() {
      return this.appointment.allAppointments;
    },
  },

  watch: {},

  methods: {
    formatDateTime,
    formatTime,
    formatDate,
    formatEndTime,
    getDayOfWeek,
    appointmentStatus,
    appointmentClick(item) {
      for (let i = 0; i < this.allAppointments.length; i++) {
        if (this.allAppointments[i]._id === item._id) {
          this.selectedItem = this.allAppointments[i];
        }
      }
      this.showAppointmentInfo = true;
    },
  },
};
</script>

<style scoped>
.appointmentCard {
  border-left: 7px solid #da1f84;
  min-height: 110px;
  width: 70%;
}

.reminderCard {
  border-left: 7px solid #ffb703;
  min-height: 110px;
  width: 70%;
}

.callbackCard {
  border-left: 7px solid #2a9d8f;
  min-height: 110px;
  width: 70%;
}

.appointmentCardTime {
  float: right;
}

.appointmentCardPopUp {
  border-left: 5px solid #da1f84;
  height: 80px;
  width: 90%;
}

.reminderCardPopUp {
  border-left: 5px solid #ffb703;
  height: 80px;
  width: 90%;
}

.callbackCardPopUp {
  border-left: 5px solid #2a9d8f;
  height: 80px;
  width: 90%;
}

.appointmentCardTimePopUp {
  float: right;
  font-size: 0.9rem;
}

.dayTitlePopUp {
  font-size: 1rem;
}

.appointmentTitlePopUp {
  font-size: 1rem;
}
</style>
