import { defineStore } from "pinia";
import { authHeader } from "../utils/auth-header";
import { handleResponse } from "../utils/api_functions";
import config from "../../config";

export const useStatisticsStore = defineStore("statistics", {
  state: () => ({
    statistics: [],
  }),

  getters: {
    allStatistics: (state) => {
      return state.statistics;
    },
  },

  actions: {
    async getAllStatistics(isoDate) {
      try {
        const requestOptions = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(
          `${config.apiUrl}/tenant/statistics/${isoDate}`,
          requestOptions
        )
          .then(handleResponse)
          .then((res) => {
            this.statistics = res;
            return res;
          });
      } catch (error) {
        console.error(error);
      }
    },
    async updateMonthlyHours(updatedHours) {
      try {
        const requestOptions = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(updatedHours),
        };
        return await fetch(
          `${config.apiUrl}/tenant/statistics/monthlyHours`,
          requestOptions
        )
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        console.error(error);
      }
    },
  },
});
