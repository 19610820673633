<template>
  <v-card
    v-if="auth.user.role == 1 || auth.user.role == 3"
    class="ma-4"
    elevation="1"
    width="60%"
  >
    <v-card-title>CALL BACK</v-card-title>
    <v-table>
      <thead>
        <tr>
          <th class="text-left">Bedrijf</th>
          <th class="text-left">Beller</th>
          <th class="text-left">Date</th>
          <th class="text-left">Appointment</th>
          <th class="text-left">Status</th>
        </tr>
      </thead>
      <tbody v-if="auth.user.role == 3">
        <tr v-for="item in appointment.allCallBacks" :key="item._id">
          <td>{{ getProfile(item.profileId) }}</td>
          <td>{{ getEmployee(item.employeeId) }}</td>
          <td>
            <v-chip
              :color="overdue(item.dateTime) ? 'green' : 'red'"
              text-color="white"
            >
              {{ item.day }}</v-chip
            >
          </td>
          <td>
            <v-chip
              :color="
                item.status === 'CANCELLED'
                  ? 'red'
                  : item.status === 'ACTIVE'
                  ? 'green'
                  : 'blue'
              "
              text-color="white"
            >
              {{ appointmentStatus(item.status) }}
            </v-chip>
          </td>
          <td>
            <div>
              <DropdownChip
                :menuItems="leadStatuses"
                :item="formatState(item.profileId.leadState)"
                itemText="text"
                itemValue="value"
                @updated="
                  (data) => {
                    inTableEdit(
                      data,
                      item.profileId._id,
                      item._id,
                      'leadState'
                    );
                  }
                "
                @cleared="showWarningMessage()"
              />
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-if="auth.user.role == 1">
        <tr v-for="item in appointment.userCallBacks" :key="item._id">
          <td>{{ getProfile(item.profileId) }}</td>
          <td>{{ getEmployee(item.employeeId) }}</td>
          <td>
            <v-chip
              :color="overdue(item.dateTime) ? 'green' : 'red'"
              text-color="white"
            >
              {{ item.day }}</v-chip
            >
          </td>
          <td>
            <v-chip
              :color="
                item.status === 'CANCELLED'
                  ? 'red'
                  : item.status === 'ACTIVE'
                  ? 'green'
                  : 'blue'
              "
              text-color="white"
            >
              {{ appointmentStatus(item.status) }}
            </v-chip>
          </td>
          <td>
            <div>
              <DropdownChip
                :menuItems="leadStatuses"
                :item="formatState(item.profileId.leadState)"
                itemText="text"
                itemValue="value"
                @updated="
                  (data) => {
                    inTableEdit(
                      data,
                      item.profileId._id,
                      item._id,
                      'leadState'
                    );
                  }
                "
                @cleared="showWarningMessage()"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </v-table>
  </v-card>
</template>

<script setup>
import { ref } from "vue";
import { LeadStateEnum } from "../enums";
import { useAppointmentStore } from "@/stores/appointment";
import { useProfileStore } from "@/stores/profile";
import { useAlertsStore } from "@/stores/alerts";
import { useAuthStore } from "@/stores/auth";
import { appointmentStatus } from "../utils/enum_helper";
import DropdownChip from "@/components/DropdownChip.vue";

const appointment = useAppointmentStore();
const profile = useProfileStore();
const alerts = useAlertsStore();
const auth = useAuthStore();
appointment.getAllAppointments();

const leadStatuses = ref([]);
Object.keys(LeadStateEnum).forEach(function (key) {
  const item = { ...LeadStateEnum[key] };
  if (item.subText) {
    item.text += " - " + item.subText;
  }
  item.value = key;
  leadStatuses.value.push(item);
});

function getEmployee(employee) {
  if (employee) {
    return employee.firstName + " " + employee.lastName;
  }
  return "Not Assigned";
}
function getProfile(profile) {
  return profile.name;
}
function overdue(date) {
  let yesterday = new Date();
  let appointment_date = new Date(date);
  yesterday.setDate(yesterday.getDate() - 1);
  return appointment_date > yesterday;
}
function formatState(state) {
  return LeadStateEnum[state];
}
async function inTableEdit(data, profileId, appointmentId, field) {
  if (data === "CALL_BACK" || data === "NO_TIME_CALL") {
    alerts.warn("Please select another status");
  } else {
    const updatedProfile = {
      profile: profileId,
      update: { [field]: data },
    };
    await profile.updateProfile(updatedProfile);
    let appointmentData = {
      status: "COMPLETED",
    };
    let updatedAppointment = {
      appointment: appointmentId,
      update: appointmentData,
    };
    await this.appointment.updateAppointment(updatedAppointment);
    appointment.getAllAppointments();
  }
}
function showWarningMessage() {
  console.log("HERE");
  alerts.warn("Status cannot be cleared on this page");
  setTimeout(() => {
    alerts.clear();
  }, 3000);
}
</script>

<style scoped></style>
