import { defineStore } from "pinia";
import config from "../../config";
import { authHeader } from "../utils/auth-header";
import { handleResponse } from "../utils/api_functions";
import { useSocketStore } from "@/stores/socket";
import { useAlertsStore } from "@/stores/alerts";
import router from "../router";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    token: localStorage.getItem("token"),
    tenant: localStorage.getItem("tenant"),
    loggedin: false,
    user: null,
    loading: true,
    employees: [],
  }),

  getters: {
    allEmployees: (state) => {
      return state.employees;
    },
    activeEmployees: (state) => {
      return state.employees.filter((employee) => employee.isDeleted !== true);
    },
  },

  actions: {
    async login(credentials) {
      try {
        const requestOptions = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(credentials),
        };
        return await fetch(`${config.apiUrl}/admin/login`, requestOptions)
          .then(handleResponse)
          .then(async (res) => {
            localStorage.setItem("token", `Bearer ${res.jwt}`);
            this.token = `Bearer ${res.jwt}`;
            localStorage.setItem("tenant", res.tenant);
            this.tenant = res.tenant;
            const user = await this.fetchUser();
            return user;
          });
      } catch (error) {
        const alerts = useAlertsStore();
        alerts.error("Credentials are invalid");
        setTimeout(function () {
          //! Calling the clear here
          alerts.clear();
        }, 5500);
        console.log(error);
      }
    },

    async fetchUser() {
      try {
        const requestOptions = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/tenant/employee`, requestOptions)
          .then(handleResponse)
          .then((res) => {
            this.user = res;
            this.loggedin = true;
            this.loading = false;
            const socket = useSocketStore();
            socket.connect();
            this.getAllEmployees();
            return res;
          });
      } catch (error) {
        if (error == "Token Expired") {
          this.logout();
        } else {
          console.error(error);
        }
      }
    },

    async getAllEmployees() {
      try {
        const requestOptions = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/tenant/employees`, requestOptions)
          .then(handleResponse)
          .then((res) => {
            this.employees = res;
            this.employees.forEach((employee) => {
              employee.fullName = employee.firstName + " " + employee.lastName;
            });
          });
      } catch (error) {
        console.error(error);
      }
    },

    async register(employee) {
      try {
        const requestOptions = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(employee),
        };
        return await fetch(`${config.apiUrl}/tenant/employee`, requestOptions)
          .then(handleResponse)
          .then(async (res) => {
            this.employees.push(res.employee);
            return res.tempPass;
          });
      } catch (error) {
        console.log(error);
      }
    },

    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("tenant");
      this.loggedin = false;
      this.user = null;
      this.token = null;
      router.push("/login");
    },

    async updatePassword(newPasword) {
      try {
        const requestOptions = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(newPasword),
        };
        return await fetch(`${config.apiUrl}/admin/update-pass`, requestOptions)
          .then(handleResponse)
          .then(async () => {
            return;
          });
      } catch (error) {
        console.log(error);
      }
    },

    async resetEmployeePassword(employee) {
      try {
        const requestOptions = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(employee),
        };
        return await fetch(
          `${config.apiUrl}/tenant/employee/resetpass`,
          requestOptions
        )
          .then(handleResponse)
          .then(async (res) => {
            return res;
          });
      } catch (error) {
        console.log(error);
      }
    },

    async forgottenPassword(email) {
      try {
        const requestOptions = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(email),
        };
        return await fetch(
          `${config.apiUrl}/admin/forgotten-pass`,
          requestOptions
        )
          .then(handleResponse)
          .then(async () => {
            const alerts = useAlertsStore();
            alerts.success("Email has been successfully sent");
            setTimeout(function () {
              //! Calling the clear here
              alerts.clear();
            }, 5500);
            return;
          });
      } catch (error) {
        const alerts = useAlertsStore();
        alerts.error("No account exists with this email");
        setTimeout(function () {
          //! Calling the clear here
          alerts.clear();
        }, 5500);
        console.log(error);
      }
    },

    employeeById(employeeId) {
      if (this.employees.length > 0) {
        return this.employees.find((employee) => employee._id == employeeId);
      }
      return {};
    },
  },
});
