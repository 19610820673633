<template>
  <v-container fluid class="pa-0">
    <v-dialog v-model="showAppointmentInfo"
      ><AppointmentOverview
        :selectedItem="selectedItem"
        @close="showAppointmentInfo = false"
    /></v-dialog>
    <v-dialog persistent v-model="openAppointmentDialog"
      ><CreateAppointmentDialog
        @close="openAppointmentDialog = false"
        @submit="openAppointmentDialog = false"
    /></v-dialog>
    <v-tabs :centered="true" v-model="tab">
      <v-tab value="one">Afspraken</v-tab>
      <v-tab value="two">Calendar</v-tab>
    </v-tabs>

    <v-window v-model="tab">
      <v-window-item value="one" class="pa-4">
        <AppointmentsCard :type="1" />
      </v-window-item>

      <v-window-item value="two" class="pa-4">
        <v-row
          class="pl-3 ml-3 mt-0 pt-0"
          justify="space-between"
          align="center"
        >
          <v-col>
            <v-row>
              <h4 class="mr-3">My</h4>
              <v-icon icon="mdi-square" color="#fc88c7"></v-icon>
              <h4 class="mr-3">Appointments</h4>
              <v-icon icon="mdi-square" color="#2a9d8f"></v-icon>
              <h4 class="mr-3">Call-Backs</h4>
              <v-icon icon="mdi-square" color="#ffb703"></v-icon>
              <h4>Reminders</h4>
              <v-divider class="mx-4" vertical></v-divider>
              <v-icon icon="mdi-square" color="#61a5c2"></v-icon>
              <h4 class="mr-3">No Employee Assigned</h4>
              <v-icon icon="mdi-square" color="#e5383b"></v-icon>
              <h4 class="mr-3">Cancelled</h4>
              <v-icon icon="mdi-square" color="#06d6a0"></v-icon>
              <h4>Completed</h4>
              <v-divider class="mx-4" vertical></v-divider>
              <v-icon icon="mdi-square" color="#e7e7ff"></v-icon>
              <h4>Other Appointments</h4>
            </v-row>
          </v-col>

          <v-col md="1">
            <v-btn
              size="small"
              icon="mdi-plus"
              color="#fc88c7"
              @click="openCalendar()"
            ></v-btn>
          </v-col>
        </v-row>
        <v-row class="pl-3 ml-3 mb-2 mt-0 pt-0" align="center">
          <v-col cols="auto">
            <v-switch
              v-model="allAppointmentsSwitch"
              color="#fc88c7"
              label="Show All Appointments"
              hide-details
            ></v-switch>
          </v-col>

          <v-col class="mt-1" cols="auto">
            <v-checkbox
              v-model="showAppointments"
              label="Appointments"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col class="mt-1" cols="auto">
            <v-checkbox
              v-model="showCallbacks"
              label="Call-Backs"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col class="mt-1" cols="auto">
            <v-checkbox
              v-model="showReminders"
              label="Reminders"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
        <calendar-view
          style="height: 70vh"
          :show-date="showDate"
          displayPeriodUom="month"
          :startingDayOfWeek="startingDayOfWeek"
          :displayPeriodCount="displayPeriodCount"
          :displayWeekNumbers="displayWeekNumbers"
          currentPeriodLabel="icons"
          class="theme-default"
          :showTimes="showTimes"
          :items="
            allAppointmentsSwitch
              ? calendarAppointments
              : myCalendarAppointments
          "
          @click-item="appointmentClick"
        >
          <template #header="{ headerProps }">
            <calendar-view-header
              :header-props="headerProps"
              @input="setShowDate"
            />
          </template>
        </calendar-view>
      </v-window-item>
    </v-window>
  </v-container>
</template>

<script>
import { useAppointmentStore } from "@/stores/appointment";
import { useAuthStore } from "@/stores/auth";
import { useProfileStore } from "@/stores/profile";
import AppointmentsCard from "../components/AppointmentsCard";
import AppointmentOverview from "../components/AppointmentOverview";

import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
import {
  formatDateTime,
  formatTime,
  formatDate,
  formatEndTime,
  getDayOfWeek,
} from "../utils/appointment_format_functions";
import CreateAppointmentDialog from "@/components/CreateAppointmentDialog.vue";
import "../../node_modules/vue-simple-calendar/dist/style.css";
import "../../node_modules/vue-simple-calendar/static/css/default.css";
export default {
  components: {
    CalendarView,
    CalendarViewHeader,
    CreateAppointmentDialog,
    AppointmentsCard,
    AppointmentOverview,
  },
  setup() {
    const appointment = useAppointmentStore();
    const auth = useAuthStore();
    const profile = useProfileStore();
    appointment.getAllAppointments();
    profile.getAllProfiles();
    return { appointment, auth, profile };
  },

  mounted() {},

  created() {},

  data: () => ({
    tab: "one",
    showDate: new Date(),
    startingDayOfWeek: 1,
    displayPeriodCount: 1,
    displayWeekNumbers: true,
    showTimes: true,
    selectedItem: {},
    showAppointmentInfo: false,
    openAppointmentDialog: false,
    allAppointmentsSwitch: false,
    showAppointments: true,
    showCallbacks: true,
    showReminders: true,
  }),

  computed: {
    allAppointments() {
      return this.appointment.allAppointments;
    },
    calendarAppointments() {
      let filteredCalApp = [];
      let calApp = this.appointment.calendarAppointments;
      for (let i = 0; i < calApp.length; i++) {
        calApp[i].startDate = this.formatDateTime(calApp[i].startDate);
        if (calApp[i].endDate) {
          calApp[i].endDate = this.formatDateTime(calApp[i].endDate);
        }
        //* Filter on appointment type
        if (calApp[i].type === "APPOINTMENT" && this.showAppointments) {
          filteredCalApp.push(calApp[i]);
        } else if (calApp[i].type === "CALL_BACK" && this.showCallbacks) {
          filteredCalApp.push(calApp[i]);
        } else if (calApp[i].type === "REMINDER" && this.showReminders) {
          filteredCalApp.push(calApp[i]);
        }
      }
      return filteredCalApp;
    },
    myCalendarAppointments() {
      let filteredCalApp = [];
      let calApp = this.appointment.myCalendarAppointments;
      for (let i = 0; i < calApp.length; i++) {
        calApp[i].startDate = this.formatDateTime(calApp[i].startDate);
        if (calApp[i].endDate) {
          calApp[i].endDate = this.formatDateTime(calApp[i].endDate);
        }
        //* Filter on appointment type
        if (calApp[i].type === "APPOINTMENT" && this.showAppointments) {
          filteredCalApp.push(calApp[i]);
        } else if (calApp[i].type === "CALL_BACK" && this.showCallbacks) {
          filteredCalApp.push(calApp[i]);
        } else if (calApp[i].type === "REMINDER" && this.showReminders) {
          filteredCalApp.push(calApp[i]);
        }
      }
      return filteredCalApp;
    },
  },

  watch: {},

  methods: {
    formatDateTime,
    formatTime,
    formatDate,
    formatEndTime,
    getDayOfWeek,
    setShowDate(d) {
      this.showDate = d;
    },
    appointmentClick(calendarItem) {
      for (let i = 0; i < this.allAppointments.length; i++) {
        if (this.allAppointments[i]._id === calendarItem.id) {
          this.selectedItem = this.allAppointments[i];
        }
      }
      this.showAppointmentInfo = true;
    },
    openCalendar() {
      this.openAppointmentDialog = true;
    },
  },
};
</script>

<style scoped>
.textDiv {
  font-size: 1.1rem;
  font-weight: 400;
}
.textDivDescription {
  font-size: 1.1rem;
  font-weight: 400;
}
.textDivDateTime {
  font-size: 1.1rem;
  font-weight: 400;
}
</style>
