<template>
  <v-dialog v-model="createContactMomentDialog"
    ><CreateContactMomentDialogVue
      :profileId="profile._id"
      @close="createContactMomentDialog = false"
  /></v-dialog>
  <v-dialog v-model="createProfileDialog"
    ><CreateProfileDialogVue
      @close="createProfileDialog = false"
      :data="profile"
      editMode
  /></v-dialog>
  <v-dialog v-model="confirmDeletionDialog"
    ><v-card>
      <v-card-title class="text-h7"
        >Are you sure you want to delete this profile?</v-card-title
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="confirmDeletionDialog = false">No</v-btn>
        <v-btn @click="deleteProfile(profile)">Yes</v-btn>
      </v-card-actions>
    </v-card></v-dialog
  >
  <v-card
    v-if="profile"
    width="800"
    height="100vh"
    class="pa-4 custom-overlay-card overflow-y-auto"
  >
    <v-card-title class="pr-0 pl-0 pb-4">
      <v-row class="pr-2 pl-2">
        <v-col md="3">
          <h1 class="pt-2">{{ profile.name }}</h1>
        </v-col>
        <v-col>
          <v-chip
            class="mr-0"
            size="x-large"
            v-if="profile.leadState"
            :color="formatState(profile.leadState).color"
            >{{ formatState(profile.leadState).text }}</v-chip
          >
        </v-col>
        <v-col cols="auto" class="pr-0">
          <v-btn
            variant="text"
            icon
            color="primary"
            @click="createProfileDialog = true"
          >
            <v-icon>mdi-pencil</v-icon>
            <v-tooltip activator="parent" location="start"
              >Profiel Aanpassen</v-tooltip
            >
          </v-btn>
        </v-col>
        <v-col
          cols="auto"
          class="pr-0 pl-0"
          style="margin-left: -10px !important"
        >
          <v-btn
            variant="text"
            icon
            color="red"
            @click="confirmDeletionDialog = true"
          >
            <v-icon>mdi-delete</v-icon>
            <v-tooltip activator="parent" location="start"
              >Profiel Delete</v-tooltip
            >
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card class="pa-2 mb-4 custom-card">
      <v-card-title class="pa-1">
        <h3>Info</h3>
      </v-card-title>
      <v-card-text class="pt-0 pb-0 mb-4 mt-2">
        <v-row class="pt-2">
          <v-col class="card-information" cols="12" sm="6">
            <v-row class="ma-0 mb-1"
              ><v-col cols="5" class="pa-0"
                ><strong class="information-field-name">KvK</strong></v-col
              ><v-col cols="auto" class="pa-0">{{ profile.kvk }}</v-col></v-row
            ></v-col
          >
          <v-col class="card-information" cols="12" sm="6">
            <v-row class="ma-0 mb-1"
              ><v-col cols="5" class="pa-0"
                ><strong class="information-field-name">Mail</strong></v-col
              ><v-col cols="auto" class="pa-0">{{
                profile.email
              }}</v-col></v-row
            ></v-col
          >
          <v-col class="card-information" cols="12" sm="6">
            <v-row class="ma-0 mb-1"
              ><v-col cols="5" class="pa-0"
                ><strong class="information-field-name">Tel No.</strong></v-col
              ><v-col cols="auto" class="pa-0">{{
                profile.phoneNum
              }}</v-col></v-row
            ></v-col
          >
          <v-col class="card-information" cols="12" sm="6">
            <v-row class="ma-0 mb-1"
              ><v-col cols="5" class="pa-0"
                ><strong class="information-field-name"
                  >Personeel</strong
                ></v-col
              ><v-col cols="auto" class="pa-0"
                ><span v-if="profile.employeesInService"
                  >{{ profile.employeesInService.min }} t/m
                  {{ profile.employeesInService.max }}</span
                ><span v-else>n.n.b</span></v-col
              ></v-row
            ></v-col
          >
          <v-col class="card-information" cols="12" sm="6">
            <v-row class="ma-0 mb-1"
              ><v-col cols="5" class="pa-0"
                ><strong class="information-field-name">Bezoeken</strong></v-col
              ><v-col cols="auto" class="pa-0">{{
                profile.visits
              }}</v-col></v-row
            ></v-col
          >
          <v-col class="card-information" cols="12" sm="6">
            <v-row class="ma-0 mb-1"
              ><v-col cols="5" class="pa-0"
                ><strong class="information-field-name">Locaties</strong></v-col
              ><v-col cols="auto" class="pa-0">{{
                profile.noLocations
              }}</v-col></v-row
            ></v-col
          >
          <v-col class="card-information" cols="12" sm="6">
            <v-row class="ma-0 mb-1"
              ><v-col cols="5" class="pa-0"
                ><strong class="information-field-name">Winst</strong></v-col
              ><v-col cols="auto" class="pa-0"
                >{{ profile.relationPerc }}%</v-col
              ></v-row
            ></v-col
          >
          <v-col class="card-information" cols="12" sm="6">
            <v-row class="ma-0 mb-1"
              ><v-col cols="5" class="pa-0"
                ><strong class="information-field-name"
                  >Laatste Contact</strong
                ></v-col
              ><v-col cols="auto" class="pa-0">{{
                formatDate(profile.lastContactDate)
              }}</v-col></v-row
            ></v-col
          >
          <v-col class="card-information" cols="12" sm="6">
            <v-row class="ma-0 mb-1"
              ><v-col cols="5" class="pa-0"
                ><strong class="information-field-name">Sector</strong></v-col
              ><v-col cols="auto" class="pa-0">{{
                profile.sector
              }}</v-col></v-row
            ></v-col
          >
          <v-col class="card-information" cols="12" sm="6">
            <v-row class="ma-0 mb-1"
              ><v-col cols="5" class="pa-0"
                ><strong class="information-field-name">Segment</strong></v-col
              ><v-col cols="auto" class="pa-0">{{
                profile.segment
              }}</v-col></v-row
            ></v-col
          >
          <v-col class="card-information" cols="12" sm="6">
            <v-row class="ma-0 mb-1"
              ><v-col cols="5" class="pa-0"
                ><strong class="information-field-name">Payroll</strong></v-col
              ><v-col cols="auto" class="pa-0">{{
                profile.currentPayroll
              }}</v-col></v-row
            ></v-col
          >
          <v-col class="card-information" cols="12" sm="6">
            <v-row class="ma-0 mb-1"
              ><v-col cols="5" class="pa-0"
                ><strong class="information-field-name">Planning</strong></v-col
              ><v-col cols="auto" class="pa-0">{{
                profile.currentPlan
              }}</v-col></v-row
            ></v-col
          >
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="pa-2 mb-4 custom-card">
      <v-card-title class="pa-1">
        <h3>Address</h3>
      </v-card-title>
      <v-card-text v-if="profile.address" class="pt-0 pb-0 mb-4 mt-2">
        <v-row class="pt-2">
          <v-col class="card-information" cols="4">
            <v-row class="ma-0 mb-1"
              ><v-col cols="5" class="pa-0"
                ><strong class="information-field-name">Straat</strong></v-col
              ><v-col cols="auto" class="pa-0">{{
                profile.address.street
              }}</v-col></v-row
            ></v-col
          >
          <v-col class="card-information" cols="4">
            <v-row class="ma-0 mb-1"
              ><v-col cols="5" class="pa-0"
                ><strong class="information-field-name">No.</strong></v-col
              ><v-col cols="auto" class="pa-0"
                >{{ profile.address.houseNo
                }}{{ profile.address.addition }}</v-col
              ></v-row
            ></v-col
          >
          <v-col class="card-information" cols="4">
            <v-row class="ma-0 mb-1"
              ><v-col cols="5" class="pa-0"
                ><strong class="information-field-name">Postcode</strong></v-col
              ><v-col cols="auto" class="pa-0">{{
                profile.address.postcode
              }}</v-col></v-row
            ></v-col
          >
          <v-col class="card-information" cols="4">
            <v-row class="ma-0 mb-1"
              ><v-col cols="5" class="pa-0"
                ><strong class="information-field-name">Regio</strong></v-col
              ><v-col cols="auto" class="pa-0">{{
                profile.address.region
              }}</v-col></v-row
            ></v-col
          >
          <v-col class="card-information" cols="4">
            <v-row class="ma-0 mb-1"
              ><v-col cols="5" class="pa-0"
                ><strong class="information-field-name"
                  >Provincie</strong
                ></v-col
              ><v-col cols="auto" class="pa-0">{{
                profile.address.province
              }}</v-col></v-row
            ></v-col
          >
        </v-row>
      </v-card-text>
      <span class="pl-2" v-else>Er is geen address informatie</span>
    </v-card>

    <v-card class="pa-2 mb-4 custom-card">
      <v-card-title class="pa-1">
        <h3>Contact Persoon</h3>
      </v-card-title>
      <v-card-text v-if="profile.contactPerson" class="pt-0 pb-0 mb-4 mt-2">
        <v-row class="pt-2">
          <v-col class="card-information" cols="4">
            <v-row class="ma-0 mb-1"
              ><v-col cols="5" class="pa-0"
                ><strong class="information-field-name">Naam</strong></v-col
              ><v-col cols="auto" class="pa-0"
                >{{ profile.contactPerson.initials }}
                {{ profile.contactPerson.insertion }}
                {{ profile.contactPerson.lastName }}
              </v-col></v-row
            ></v-col
          >
          <v-col class="card-information" cols="4">
            <v-row class="ma-0 mb-1"
              ><v-col cols="5" class="pa-0"
                ><strong class="information-field-name">Cell No.</strong></v-col
              ><v-col cols="auto" class="pa-0">{{
                profile.contactPerson.celNumber
              }}</v-col></v-row
            ></v-col
          >
          <v-col class="card-information" cols="4">
            <v-row class="ma-0 mb-1"
              ><v-col cols="5" class="pa-0"
                ><strong class="information-field-name">Geslacht</strong></v-col
              ><v-col cols="auto" class="pa-0">{{
                profile.contactPerson.gender
              }}</v-col></v-row
            ></v-col
          >
        </v-row>
      </v-card-text>
      <span class="pl-2" v-else>Er is geen contact persoon informatie</span>
    </v-card>

    <v-card class="pa-2 mb-4 custom-card">
      <v-card-title class="pa-1">
        <h3>Notities</h3>
      </v-card-title>
      <v-card-text v-if="profile.contactPerson" class="pt-0 pb-0 mb-4 mt-2">
        <v-row class="pt-2">
          <v-col class="card-information">
            {{ profile.notes }}
          </v-col>
        </v-row>
      </v-card-text>
      <span class="pl-2" v-else>Er is geen contact persoon informatie</span>
    </v-card>

    <v-card class="pa-2 mb-4 custom-card overflow-y-auto" max-height="40vh">
      <v-card-title class="pa-1"
        ><v-row>
          <v-col class="pt-2">
            <h3>Contact Momenten</h3>
          </v-col>
          <v-col cols="auto" class="pa-0">
            <v-btn
              @click="createContactMomentDialog = true"
              variant="text"
              icon="mdi-plus"
              color="primary"
            >
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-timeline
        align="start"
        side="end"
        v-if="profile.contactMoments.length > 0"
      >
        <v-timeline-item
          v-for="item in profile.contactMoments"
          :key="item"
          dot-color="primary"
          size="small"
          class="custom-timeline-item"
          width="500"
        >
          <template v-slot:opposite>
            <span>{{ formatDate(item.contactDate) }}</span>
          </template>
          <v-card flat>
            <v-card-title class="pa-0">
              <v-chip color="secondary" class="mr-1">{{
                auth.employeeById(item.employee).firstName
              }}</v-chip>
              <v-chip
                v-if="item.newPhase"
                class="mr-1"
                :color="formatPhase(item.newPhase).color"
                >{{ formatPhase(item.newPhase).text }}</v-chip
              >
              <v-chip
                v-if="item.newLeadState"
                class="mr-1"
                :color="formatState(item.newLeadState).color"
                >{{ formatState(item.newLeadState).text }}</v-chip
              >
            </v-card-title>
            {{ item.notes }}
          </v-card>
        </v-timeline-item>
      </v-timeline>
      <span class="pl-2" v-else>Er zijn nog geen contact momenten</span>
    </v-card>
  </v-card>
</template>

<script setup>
import { LeadStateEnum, ProfilePhaseEnum } from "../enums";
import CreateContactMomentDialogVue from "./CreateContactMomentDialog.vue";
import CreateProfileDialogVue from "@/components/CreateProfileDialog.vue";
import { useAuthStore } from "@/stores/auth";
import { useProfileStore } from "@/stores/profile";
import { defineProps, ref, defineEmits } from "vue";
const auth = useAuthStore();
const profileStore = useProfileStore();
const props = defineProps({
  profile: Object,
  openEditDialog: Boolean,
});
const emits = defineEmits(["close"]);
const createContactMomentDialog = ref(false);
const createProfileDialog = ref(props.openEditDialog);
const confirmDeletionDialog = ref(false);

function formatDate(date) {
  if (!date) return "";
  const cleanDate = new Date(date);
  const dateTime = cleanDate.toLocaleString("nl-NL").split(" ");
  const time = dateTime[1].split(":");
  return `${dateTime[0]} om ${time[0]}:${time[1]}`;
}
function formatState(state) {
  return LeadStateEnum[state];
}
function formatPhase(phase) {
  return ProfilePhaseEnum[phase];
}
async function deleteProfile(profile) {
  await profileStore.deleteProfile(profile._id).then((res) => {
    if (res.message == "Success") {
      profileStore.getAllProfiles();
      emits("close");
    }
  });
  this.confirmDeletionDialog = false;
}
</script>

<style scoped>
.custom-overlay-card {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 24px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.card-information {
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 16px;
}
.information-field-name {
  font-size: 16px;
  margin-right: 8px;
}
</style>
