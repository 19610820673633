import { defineStore } from "pinia";
import config from "../../config";
import { authHeader } from "../utils/auth-header";
import { handleResponse } from "../utils/api_functions";

export const useUtilityStore = defineStore("utility", {
  state: () => ({}),

  getters: {},

  actions: {
    async featureRequest(data) {
      try {
        const requestOptions = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(data),
        };
        return await fetch(
          `${config.apiUrl}/tenant/utilities/featureRequest`,
          requestOptions
        )
          .then(handleResponse)
          .then(() => {});
      } catch (error) {
        console.error(error);
      }
    },
  },
});
