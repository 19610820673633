<template>
  <v-container fluid class="fill-height pa-0">
    <v-dialog v-model="forgottenPasswordDialog"
      ><v-card tonal width="500px" height="250px">
        <v-card-title>Forgotten password?</v-card-title>
        <v-card-text class="mb-4"
          >A new password will be sent to the inserted email
          address</v-card-text
        >
        <v-row class="mx-8">
          <v-text-field
            v-model="forgottenPassEmail"
            class="mb-0 pb-0"
            density="compact"
            label="Email"
          ></v-text-field>
        </v-row>

        <v-card-actions class="px-4 pb-4 mt-0 pt-0">
          <v-spacer></v-spacer>
          <v-btn color="success" variant="flat" @click="resetPassword()">
            Continue
          </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
    <v-row align="center" no-gutters class="fill-height">
      <v-col
        style="background-color: rgba(218, 31, 132, 1)"
        cols="6"
        class="fill-height"
      >
        <v-row align="center" class="fill-height">
          <v-col>
            <v-img
              width="800"
              style="display: block; margin-left: auto; margin-right: auto"
              :src="require('../assets/mr-einstein-logo.png')"
            ></v-img>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row align="center"
          ><v-col></v-col>
          <v-col cols="6"
            ><v-card elevation="0" style="text-align: center">
              <v-img
                width="200"
                style="display: block; margin-left: auto; margin-right: auto"
                class="mb-8"
                :src="require('../assets/cllbr-sales-brand.svg')"
              ></v-img>
              <h1 class="pb-4">Aanmelden bij CLLBR Sales</h1>
              <h4>
                Als u niet geregistreerd bent, vraag dan uw manager om een
                account aan te maken.
              </h4>
              <v-divider class="mt-8 mb-6" />
              <v-form v-on:keyup.enter="login">
                <v-container>
                  <v-text-field
                    v-model="email"
                    hide-details
                    label="Email"
                    variant="outlined"
                    type="email"
                    autocomplete="username"
                  ></v-text-field>
                  <v-text-field
                    class="mt-4"
                    v-model="password"
                    hide-details
                    type="password"
                    label="Password"
                    variant="outlined"
                    autocomplete="current-password"
                  ></v-text-field>
                </v-container>
              </v-form>
              <v-card-actions class="mt-1" style="justify-content: center"
                ><v-btn
                  block
                  variant="elevated"
                  color="secondary"
                  @click="login"
                >
                  Login
                </v-btn></v-card-actions
              >
              <v-row class="mt-2" style="height: 50px" justify="center">
                <v-btn
                  size="small"
                  variant="text"
                  @click="openForgottenPasswordDialog()"
                  >Forgot password?</v-btn
                >
              </v-row>
            </v-card></v-col
          ><v-col></v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { useAuthStore } from "@/stores/auth";
import router from "../router";
export default {
  setup() {
    const auth = useAuthStore();

    return { auth };
  },

  created() {},

  data: () => ({
    // reactive state
    password: "",
    email: "",
    forgottenPassEmail: "",
    forgottenPasswordDialog: false,
  }),

  computed: {},

  methods: {
    async login() {
      const credentials = {
        email: this.email,
        password: this.password,
      };

      await this.auth.login(credentials).then((res) => {
        if (res) {
          if (res._id) {
            router.push("/leads");
          }
        }
      });
    },
    openForgottenPasswordDialog() {
      this.forgottenPasswordDialog = true;
    },
    async resetPassword() {
      const email = {
        email: this.forgottenPassEmail,
      };
      await this.auth.forgottenPassword(email);
      this.forgottenPasswordDialog = false;
    },
  },
};
</script>

<style scoped>
.vertical-center {
  position: absolute;
  left: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
</style>
