export function formatDateTime(string) {
  let date = new Date(string);
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-") +
    " " +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(":")
  );
}
export function formatTime(string) {
  let date = new Date(string);
  return [
    padTo2Digits(date.getHours()),
    padTo2Digits(date.getMinutes()),
    padTo2Digits(date.getSeconds()),
  ].join(":");
}
export function formatDate(string) {
  let date = new Date(string);
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join("-");
}
export function formatEndTime(end) {
  if (end !== null) {
    return " - " + formatTime(end);
  }
  return "";
}
function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export function getDayOfWeek(s) {
  const [dd, mm, yyyy] = s.split("/"),
    date = new Date(yyyy, mm - 1, dd);
  return date.toLocaleDateString("en-US", { weekday: "long" });
}
