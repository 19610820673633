<template>
  <v-app>
    <MainNav v-if="auth.loggedin" :appBarHeight="appBarHeight">
      <!-- -->
    </MainNav>
    <v-app-bar
      :model-value="auth.loggedin"
      absolute
      ref="appBar"
      elevation="1"
      app
    >
      <v-file-input
        id="fileUpload"
        @change="selectFile($event)"
        :key="componentKey"
        type="file"
        style="display: none"
        accept="image/*"
      ></v-file-input>

      <v-dialog v-model="changePasswordDialog"
        ><ChangePasswordDialog @close="changePasswordDialog = false"
      /></v-dialog>

      <v-dialog persistent v-model="openAppointmentDialog"
        ><CreateAppointmentDialog
          @close="openAppointmentDialog = false"
          @submit="openAppointmentDialog = false"
      /></v-dialog>

      <v-menu
        activator="#profile-menu-activator"
        :close-on-content-click="true"
        location="start"
        transition="slide-y-transition"
        :offset-y="offset"
      >
        <v-card width="280">
          <v-list>
            <v-list-item v-for="(item, index) in menuItems" :key="index">
              <v-btn block @click="performAction(item.action)">{{
                item.title
              }}</v-btn>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

      <v-menu
        activator="#appointment-menu-activator"
        :close-on-content-click="false"
        location="bottom"
      >
        <v-card width="450" max-height="500" class="pa-0">
          <AppointmentsCard :type="2" />
        </v-card>
      </v-menu>

      <v-row justify="end" align="center">
        <v-col cols="auto" class="pr-0 pl-0">
          <v-list-item class="pr-0 pl-0">
            <template v-slot:append>
              <v-btn
                size="default"
                variant="text"
                icon
                id="appointment-menu-activator"
              >
                <v-badge color="info" :model-value="false" content="12">
                  <v-icon size="large">mdi-calendar</v-icon>
                </v-badge>
              </v-btn>
            </template>
          </v-list-item>
        </v-col>
        <v-col cols="auto" class="pr-0 pl-0">
          <v-list-item class="pr-0 pl-0">
            <v-btn
              size="default"
              variant="text"
              icon
              @click="createAppointment"
            >
              <v-badge color="info" :model-value="false" content="12">
                <v-icon size="large">mdi-calendar-edit</v-icon>
              </v-badge>
            </v-btn>
            <v-tooltip activator="parent" location="bottom"
              >Afspraak inplannen</v-tooltip
            >
          </v-list-item>
        </v-col>
        <v-col cols="auto" class="pr-0 pl-0">
          <v-list-item class="pr-2 pl-0">
            <template v-slot:append>
              <v-btn size="default" variant="text" icon>
                <v-badge color="info" :model-value="false" content="12">
                  <v-icon size="large">mdi-bell-outline</v-icon>
                </v-badge>
              </v-btn>
            </template>
          </v-list-item>
        </v-col>

        <v-col cols="auto" class="pr-0 pl-0">
          <v-list-item
            class="pr-0"
            v-if="auth.user"
            :prepend-avatar="
              computedImage != '' ? computedImage : require('./assets/ein.png')
            "
            :title="`${auth.user.firstName} ${auth.user.lastName}`"
          >
            <template v-slot:append>
              <v-btn
                variant="text"
                icon="mdi-chevron-down"
                id="profile-menu-activator"
              ></v-btn>
            </template>
          </v-list-item>
        </v-col>
      </v-row>
      <!-- -->
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid class="pa-0 fill-height">
        <v-alert
          v-model="display"
          border
          closable
          close-label="Close Alert"
          :type="type"
          max-width="30%"
          transition="slide-x-transition"
          style="position: fixed; top: 15px; z-index: 20001; right: 15px"
          >{{ message }}
        </v-alert>
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import MainNav from "@/components/MainNav.vue";
import einsteinLogo from "@/assets/mr-einstein-logo.png";
import cllbrLogo from "@/assets/CLLBR_Title_Logo.png";
import { useAuthStore } from "@/stores/auth";
import { useAlertsStore } from "@/stores/alerts";
import { useEmployeeStore } from "@/stores/employee";
import { useTheme } from "vuetify";
import { storeToRefs } from "pinia";
import CreateAppointmentDialog from "@/components/CreateAppointmentDialog.vue";
import AppointmentsCard from "./components/AppointmentsCard";
import ChangePasswordDialog from "./components/ChangePasswordDialog.vue";

export default {
  name: "App",
  components: {
    MainNav,
    ChangePasswordDialog,
    AppointmentsCard,
    CreateAppointmentDialog,
  },

  setup() {
    document.title = "CLLBR Sales";
    const auth = useAuthStore();
    const alerts = useAlertsStore();
    const employee = useEmployeeStore();
    //* Reactive Properties According to pinia documentation
    const { display, type, message } = storeToRefs(alerts);

    if (auth.token) {
      auth.fetchUser();
    } else {
      auth.loading = false;
    }
    const theme = useTheme();
    //console.log(theme);
    //theme.name.value = "customDark";

    let appBarColor, companyLogo;
    let tempLogin = true;
    //TODO Dark theme implimentation
    if (tempLogin) {
      appBarColor = "#DA1F84";
      companyLogo = einsteinLogo;
    } else {
      appBarColor = "#fafafa";
      companyLogo = cllbrLogo;
    }

    return {
      appBarColor,
      companyLogo,
      auth,
      theme,
      alerts,
      display,
      type,
      message,
      employee,
    };
  },

  mounted() {
    this.appBarHeight = this.$refs.appBar.height;
  },

  created() {
    // this.companyLogoWidth =
    //   (this.$refs.titleLogo.naturalWidth / this.$refs.titleLogo.naturalHeight) *
    //   this.$refs.appBar.height;
    //console.log(this.$refs.titleLogo);
  },

  computed: {
    computedImage() {
      if (this.auth.user.profilePictureFile != null) {
        let url = "";
        url = "data:image/jpeg;base64," + this.auth.user.profilePictureFile;

        return url;
      } else {
        return "";
      }
    },
  },

  data: () => ({
    appBarHeight: 0,
    menuItems: [
      { title: "Verander profiel foto", action: "changeAvatar" },
      { title: "Verander wachtwoord", action: "changePassword" },
      { title: "Logout", action: "logout" },
    ],
    componentKey: 0,
    offset: true,
    changePasswordDialog: false,
    openAppointmentDialog: false,
  }),

  methods: {
    performAction(action) {
      switch (action) {
        case "changeAvatar":
          this.openFileInput();

          break;
        case "logout":
          this.auth.logout();
          break;
        case "changePassword":
          this.changePasswordDialog = true;
          break;
        default:
          break;
      }
    },
    openFileInput() {
      document.getElementById("fileUpload").click();
    },
    async selectFile(event) {
      this.currentFile = event.target.files[0];

      if (!this.currentFile) {
        return;
      }
      var fileToUpload = this.currentFile;
      let uploadData = {
        id: this.auth.user._id,
      };

      //* Upload Pic
      await this.employee.uploadProfilePic({
        fileToUpload,
        uploadData,
      });

      await this.auth.fetchUser();
      this.componentKey++;
    },
    createAppointment() {
      this.openAppointmentDialog = true;
    },
  },
};
</script>
<style>
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background: rgba(var(--v-theme-secondary), 1);
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(var(--v-theme-secondary), 1);
}
</style>
