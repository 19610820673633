<template>
  <v-card rounded="xl">
    <v-card-title
      class="mt-4 text-center"
      style="margin-bottom: -30px"
      justify="center"
    >
      <div>
        <h3 class="headline mb-0">{{ firstName }}</h3>
      </div>
      <v-card-text justify="center">
        <v-textarea
          outlined
          label="Notities"
          @change="updateNotes()"
          v-model="editedNotes"
          name="description"
        ></v-textarea>
      </v-card-text>
    </v-card-title>
    <img
      v-if="showQR"
      :src="`https://quickchart.io/qr?text=${generateString}&size=400`"
      alt="QR Code"
      width="300"
      height="300"
    />
    <v-card-actions class="mb-3 mx-8" style="margin-top: 20px">
      <v-row>
        <v-select
          :items="fondnesses"
          item-title="text"
          item-value="value"
          label="Soort Profiel"
          v-model="selectedFondness"
          variant="plain"
          density="compact"
          hide-details
        ></v-select>
        <v-btn @click="updateFondness()">Save</v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ProfileFondnessEnum } from "../enums";
import { useProfileStore } from "@/stores/profile";
export default {
  props: {
    showQR: {
      default: true,
      type: Boolean,
    },
    size: {
      default: 40,
      type: Number,
    },
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
    birthday: String,
    gender: String,
    workPhone: String,
    homePhone: String,
    workEmail: String,
    homeEmail: String,
    orgName: String,
    orgTitle: String,
    homeStreet: {
      default: "",
      type: String,
    },
    homeCity: {
      default: "",
      type: String,
    },
    homeRegion: {
      default: "",
      type: String,
    },
    homePost: {
      default: "",
      type: String,
    },
    homeCountry: {
      default: "",
      type: String,
    },
    orgStreet: {
      default: "",
      type: String,
    },
    orgCity: {
      default: "",
      type: String,
    },
    orgRegion: {
      default: "",
      type: String,
    },
    orgPost: {
      default: "",
      type: String,
    },
    orgCountry: {
      default: "",
      type: String,
    },
    notes: {
      type: String,
    },
    fondness: {
      type: String,
    },
    profileId: {
      type: String,
    },
  },
  created() {
    this.addPersonalInfo();
    this.addContactInformation();
    this.addWorkDetails();
    this.addAddressDetails();
    this.populateFondness();
    this.editedNotes = this.notes;
  },
  data() {
    return {
      vCardStart: "BEGIN:VCARD\nVERSION:3.0\n",
      vCardEnd: "\nEND:VCARD",
      vCard: "",
      fondnesses: [],
      selectedFondness: "Normaal",
      editedNotes: "",
    };
  },
  methods: {
    populateFondness() {
      let arr = [];
      Object.keys(ProfileFondnessEnum).forEach(function (key) {
        let item = ProfileFondnessEnum[key];
        item.value = key;
        arr.push(item);
      });
      this.fondnesses = arr;
      if (this.fondness) {
        this.selectedFondness = this.fondness;
      }
    },
    async updateFondness() {
      const profile = useProfileStore();
      const updatedProfile = {
        profile: this.profileId,
        update: { fondness: this.selectedFondness },
      };
      await profile.updateProfile(updatedProfile);
      await profile.getAllProfiles();
    },
    async updateNotes() {
      const profile = useProfileStore();
      const updatedProfile = {
        profile: this.profileId,
        update: { notes: this.editedNotes },
      };
      await profile.updateProfile(updatedProfile);
      await profile.getAllProfiles();
    },
    addPersonalInfo: function () {
      this.vCard +=
        "N:" +
        this.lastName +
        ";" +
        this.firstName +
        "\n" +
        "FN:" +
        this.firstName +
        " " +
        this.lastName;

      if (this.birthday !== undefined) {
        this.vCard += "\nBDAY:" + this.birthday;
      }

      if (this.gender !== undefined) {
        this.vCard += "\nX-GENDER:" + this.gender;
      }
    },
    addContactInformation: function () {
      // Add if phone number is provided
      if (this.homePhone !== undefined) {
        this.vCard += "\nTEL;TYPE=home:" + this.homePhone;
      }

      if (this.workPhone !== undefined) {
        this.vCard += "\nTEL;TYPE=work:" + this.workPhone;
      }

      // Add if email is provided
      if (this.workEmail !== undefined) {
        this.vCard += "\nEMAIL;TYPE=internet,work:" + this.workEmail;
      }

      if (this.homeEmail !== undefined) {
        this.vCard += "\nEMAIL;TYPE=internet,home:" + this.homeEmail;
      }
    },
    addWorkDetails: function () {
      if (this.orgTitle !== undefined) {
        this.vCard += "\nTITLE:" + this.orgTitle;
      }

      if (this.orgName !== undefined) {
        this.vCard += "\nORG:" + this.orgName;
      }
    },
    addAddressDetails: function () {
      if (
        this.homeStreet +
          this.homeCity +
          this.homeRegion +
          this.homePost +
          this.homeCountry !==
        ""
      ) {
        this.vCard +=
          "\nADR;TYPE=home:;;" +
          this.homeStreet +
          ";" +
          this.homeCity +
          ";" +
          this.homeRegion +
          ";" +
          this.homePost +
          ";" +
          this.homeCountry;
      }
      if (
        this.orgStreet +
          this.orgCity +
          this.orgRegion +
          this.orgPost +
          this.orgCountry !==
        ""
      ) {
        this.vCard +=
          "\nADR;TYPE=work:;;" +
          this.orgStreet +
          ";" +
          this.orgCity +
          ";" +
          this.orgRegion +
          ";" +
          this.orgPost +
          ";" +
          this.orgCountry;
      }
    },
  },
  computed: {
    generateString: function () {
      let vString = this.vCardStart + this.vCard + this.vCardEnd;
      let QRString = vString.replace(/\n/g, "%0A");
      this.$emit("QRString", QRString);
      return QRString;
    },
  },
};
</script>
