<template>
  <v-dialog v-model="employeeDialog"
    ><EmployeeDialog @close="createdEmployee"
  /></v-dialog>

  <v-dialog v-model="editEmployeeDialog">
    <EditEmployeeDialog
      :employee="selectedEmployee"
      @close="editEmployeeDialog = false"
    />
  </v-dialog>
  <v-file-input
    @change="selectFile($event)"
    :key="componentKey"
    id="employeeFileUpload"
    type="file"
    style="display: none"
    accept="image/*"
  ></v-file-input>
  <v-container fluid class="pa-8">
    <v-row class="pr-2 mb-2">
      <v-col>
        <h1 class="pt-2">Medewerkers</h1>
      </v-col>
      <v-col cols="auto" class="pr-0">
        <v-btn
          variant="text"
          icon
          color="primary"
          @click="employeeDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
          <v-tooltip activator="parent" location="start"
            >Medewerker Aanmaken</v-tooltip
          >
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="pl-4">
      <v-col
        v-for="employee in auth.user.role == 4
          ? auth.allEmployees
          : auth.activeEmployees"
        :key="employee._id"
        cols="auto"
      >
        <v-card
          elevation="3"
          width="250"
          class="mt-4"
          :class="employee.isDeleted ? 'isDeleted' : ''"
          tile
        >
          <v-container class="pt-0 pb-0 pl-4 pr-0">
            <v-row no-gutters>
              <v-col class="pt-4" cols="auto"
                ><v-avatar size="x-large">
                  <v-img
                    :src="
                      getImage(employee) != ''
                        ? getImage(employee)
                        : require('../assets/ein.png')
                    "
                  ></v-img> </v-avatar
              ></v-col>
              <v-col class="pl-4 pt-4"
                >{{ employee.firstName }} {{ employee.lastName }}<br />
                {{ formatRole(employee.role).name }}
              </v-col>
              <v-col v-if="!employee.isDeleted" class="pt-2" cols="auto">
                <v-btn
                  v-if="
                    auth.user.role == 3 ||
                    auth.user.role == 4 ||
                    auth.user._id == employee._id
                  "
                  variant="text"
                  icon
                  id="employee-menu"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                  <v-menu
                    activator="parent"
                    :close-on-content-click="true"
                    location="bottom"
                    transition="slide-y-transition"
                    :offset-y="true"
                  >
                    <v-list>
                      <v-list-item @click="resetEmployeePas(employee)">
                        Reset wachtwoord
                      </v-list-item>
                      <!-- if admin or developer is logged in they can edit the information about the employee and the employee avatar -->
                      <v-list-item @click="editEmployee(employee)"
                        >Werknemersgegevens bewerken</v-list-item
                      >
                      <v-list-item @click="editProfilePicture(employee)"
                        >Profielfoto veranderen</v-list-item
                      >
                      <v-list-item
                        v-if="
                          (auth.user.role == 4 || auth.user.role == 3) &&
                          auth.user._id != employee._id
                        "
                        @click="deleteEmployee(employee)"
                        >Delete Employee</v-list-item
                      >
                    </v-list>
                  </v-menu>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-card-text style="text-align: center">
            <span
              ><v-icon class="pr-4">mdi-email</v-icon>{{ employee.email }}</span
            ><br />
            <span
              ><v-icon class="pr-4">mdi-phone</v-icon>{{ employee.cel }}</span
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <v-snackbar v-model="snackbar">
    {{ snackbarText }}
    <template v-slot:actions>
      <v-btn color="pink" variant="text" @click="snackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>
import { EmployeeRoleEnum } from "../enums";
import { ref } from "vue";
import { useAuthStore } from "@/stores/auth";
import EmployeeDialog from "@/components/EmployeeDialog.vue";
import EditEmployeeDialog from "@/components/EditEmployeeDialog.vue";
import { useEmployeeStore } from "@/stores/employee";

const auth = useAuthStore();
const employeeStore = useEmployeeStore();
const employeeDialog = ref(false);
const editEmployeeDialog = ref(false);
const snackbar = ref(false);
const snackbarText = ref(null);
let componentKey = 0;
const selectedEmployee = ref(null);

function formatRole(role) {
  return EmployeeRoleEnum[role];
}
async function createdEmployee(pass) {
  employeeDialog.value = false;
  snackbarText.value = `Medewerker aangemaakt met tijdelijk wachtwoord: ${pass}`;
  snackbar.value = true;
  await auth.getAllEmployees();
}
function getImage(employee) {
  if (employee.profilePictureFile != null) {
    let url = "";
    url = "data:image/jpeg;base64," + employee.profilePictureFile;

    return url;
  } else {
    return "";
  }
}
async function resetEmployeePas(employee) {
  await auth.resetEmployeePassword(employee).then((res) => {
    snackbarText.value = `Het tijdelijke wachtwoord is: ${res.tempPass}`;
    snackbar.value = true;
  });
}

async function editEmployee(employee) {
  selectedEmployee.value = employee;
  editEmployeeDialog.value = true;
  await auth.getAllEmployees();
}

function editProfilePicture(employee) {
  selectedEmployee.value = employee;
  document.getElementById("employeeFileUpload").click();
}

async function deleteEmployee(employee) {
  const id = employee._id;
  await employeeStore.deleteEmployee(id).then((res) => {
    snackbarText.value = res.message;
    snackbar.value = true;
  });
  await auth.getAllEmployees();
}

async function selectFile(event) {
  let currentFile = event.target.files[0];

  if (!currentFile) {
    return;
  }
  var fileToUpload = currentFile;
  let uploadData = {
    id: selectedEmployee.value._id,
  };

  //* Upload Pic
  await employeeStore.uploadProfilePic({
    fileToUpload,
    uploadData,
  });
  await auth.getAllEmployees();
  componentKey++;
}
</script>

<style scoped>
.isDeleted {
  border-bottom: 4px solid #ff2c2c;
}
</style>
