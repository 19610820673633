<template>
  <v-row justify="center">
    <v-card width="800" class="pa-4 overflow-y-auto" max-height="80vh">
      <v-card-title class="pa-4 pb-0">
        <span class="text-h5">Werknemersgegevens bewerken</span>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-form ref="creationForm" class="custom-form" v-model="valid">
          <v-container>
            <v-row class="mb-2">
              <v-col cols="12" sm="6">
                <v-text-field
                  :rules="rules.required"
                  v-model="formData.firstName"
                  label="Voornaam"
                  required
                  :hide-details="hideDetails"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  :rules="rules.required"
                  v-model="formData.lastName"
                  label="Achternaam"
                  required
                  :hide-details="hideDetails"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  :rules="rules.required"
                  v-model="formData.email"
                  label="Email"
                  required
                  :hide-details="hideDetails"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  :rules="rules.required"
                  v-model="formData.cel"
                  label="Telefoon"
                  required
                  :hide-details="hideDetails"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  :items="employeeRoles"
                  item-title="name"
                  item-value="value"
                  label="Rol"
                  :rules="rules.required"
                  v-model="formData.role"
                  required
                  :hide-details="hideDetails"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          size="large"
          variant="flat"
          @click="editEmployee"
        >
          WIJZIGINGEN OPSLAAN
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script setup>
import { EmployeeRoleEnum } from "../enums";
import { ref, defineEmits, defineProps, computed } from "vue";
import { useAuthStore } from "@/stores/auth";
import { useEmployeeStore } from "@/stores/employee";
const props = defineProps({
  employee: Object,
});
const auth = useAuthStore();
const employee = useEmployeeStore();
const emits = defineEmits(["close"]);
const creationForm = ref(null);
const valid = ref(null);
const hideDetails = ref(true);
const formData = computed(() => {
  let vals = {};
  if (props.employee) {
    vals = props.employee;
    vals.role = vals.role.toString();
  } else {
    vals = {};
  }
  return vals;
});
const rules = {
  required: [(v) => !!v || "Verplicht"],
};

// ENUMS >> Array lists
const employeeRoles = ref([]);
Object.keys(EmployeeRoleEnum).forEach(function (key) {
  const item = { ...EmployeeRoleEnum[key] };
  item.value = key;
  employeeRoles.value.push(item);
});

// Functions
async function editEmployee() {
  const id = formData.value._id;

  const data = {
    cel: formData.value.cel,
    email: formData.value.email,
    firstName: formData.value.firstName,
    lastName: formData.value.lastName,
    role: parseInt(formData.value.role),
  };
  let employeeData = {
    id: id,
    data: data,
  };
  await employee.updateEmployee(employeeData).then(() => {
    emits("close");
  });
}
</script>

<script>
export default {};
</script>

<style scoped>
.custom-form:deep(.v-input__details) {
  margin: 0px;
}
</style>
