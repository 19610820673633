<template>
  <v-row justify="center">
    <v-card width="600" class="pa-4 overflow-y-auto">
      <v-card-title class="mb-4">
        <span class="text-h4">Request Feature/Report Bug</span>
      </v-card-title>
      <v-card-text class="">
        <v-text-field v-model="title" label="Title"></v-text-field>
        <v-textarea
          v-model="description"
          name="input-7-1"
          label="Description"
          hint="Ex. I want a new feature that allows me to do X"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          size="large"
          variant="flat"
          @click="submitFeatureRequest"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import { useUtilityStore } from "@/stores/utility";
import { useAuthStore } from "@/stores/auth";
const utility = useUtilityStore();
const auth = useAuthStore();
const emits = defineEmits(["close"]);
const title = ref("");
const description = ref("");

// Functions
async function submitFeatureRequest() {
  const data = {
    name: auth.user.firstName + " " + auth.user.lastName,
    title: title.value,
    description: description.value,
  };
  await utility.featureRequest(data).then(() => {
    emits("close");
  });
}
</script>

<style scoped></style>
