import ProfilePhaseReasonEnum from "./ProfilePhaseReasonEnum";

const ProfilePhaseEnum = Object.freeze({
  LEAD: { text: "Lead", color: "#58E745" },
  FIRST_APPOINTMENT: { text: "Eerste afspraak", color: "#23967F" },
  FOLLOWUP_APPOINTMENT: { text: "Vervolgafspreaak", color: "#E4572E" },
  PORTAL_SETUP: { text: "Inrichting portaal", color: "#F2AF29" },
  CUSTOMER: { text: "Klant", color: "#3772FF" },
  NOT_CUSTOMER: {
    text: "Geen klant",
    color: "#AD343E",
    subOptions: [
      ProfilePhaseReasonEnum.NOT_INTRESTED,
      ProfilePhaseReasonEnum.STOPPED,
      ProfilePhaseReasonEnum.NO_CHANGE,
      ProfilePhaseReasonEnum.OTHER_PARTY,
      ProfilePhaseReasonEnum.EXPENSIVE,
      ProfilePhaseReasonEnum.OTHER,
    ],
  },
});

export default ProfilePhaseEnum;
