<template>
  <v-container fluid class="pa-0">
    <v-card elevation="0" class="pa-4">
      <v-card-title class="pr-0 pl-0 pb-8">
        <v-row class="pr-2 pl-2">
          <v-col>
            <h1 class="pt-2">Statistics</h1>
          </v-col>
          <v-col cols="auto">
            <Datepicker
              v-model="month"
              monthPicker
              @update:modelValue="getStatistics()"
          /></v-col>
          <v-col cols="auto">
            <v-btn variant="text" @click="downloadExcel()"
              ><v-icon color="green" size="x-large">mdi-file-excel</v-icon>
              <v-tooltip activator="parent" location="start"
                >Excel Export</v-tooltip
              ></v-btn
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-card v-if="month !== null" rounded="xl" class="pa-2 custom-card">
        <v-table>
          <thead>
            <tr>
              <th class="text-left">Beller</th>
              <th class="text-left">Uren Gebeld</th>
              <th class="text-left">Gemaakte Afspraken</th>
              <th class="text-left">Geannuleerde afspraken</th>
              <th class="text-left">Netto afspraken</th>
              <th class="text-left">Klant geworden</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in allStatistics" :key="item.employee">
              <td>
                {{ item.employee.firstName + " " + item.employee.lastName }}
              </td>
              <td>
                <v-text-field
                  v-model="item.monthlyHours.hours"
                  class="my-2"
                  hide-details
                  density="compact"
                  label="Uren"
                  variant="outlined"
                  type="number"
                  style="max-width: 150px"
                  @keydown.enter="saveHours(item)"
                ></v-text-field>
              </td>
              <td>
                <v-chip variant="flat" color="#2a9d8f" class="ma-1">
                  {{ item.allAppointments }}</v-chip
                >
              </td>
              <td>
                <v-chip variant="flat" color="#2a9d8f" class="ma-1">
                  {{ item.cancelledAppointments }}</v-chip
                >
              </td>
              <td>
                <v-chip variant="flat" color="#2a9d8f" class="ma-1">
                  {{ item.nettoAppointments }}</v-chip
                >
              </td>
              <td>
                <v-chip variant="flat" color="#2a9d8f" class="ma-1">
                  {{ item.customers }}</v-chip
                >
              </td>
            </tr>
            <tr class="customRow">
              <td style="font-weight: 700">Totaal</td>

              <td>
                <v-chip variant="flat" color="#" class="ma-1">{{
                  getTotals.totalHours
                }}</v-chip>
              </td>
              <td>
                <v-chip variant="flat" color="#e63946" class="ma-1">{{
                  getTotals.totalAppointments
                }}</v-chip>
              </td>
              <td>
                <v-chip variant="flat" color="#e63946" class="ma-1">{{
                  getTotals.totalCancelledAppointments
                }}</v-chip>
              </td>
              <td>
                <v-chip variant="flat" color="#e63946" class="ma-1">{{
                  getTotals.totalNettoAppointments
                }}</v-chip>
              </td>
              <td>
                <v-chip variant="flat" color="#e63946" class="ma-1">{{
                  getTotals.totalCustomers
                }}</v-chip>
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-card>
      <v-btn
        v-if="auth.user.role == 4"
        class="mt-5"
        color="red"
        @click="updateInfo()"
        >DON'T PRESS</v-btn
      >
    </v-card>
  </v-container>
</template>

<script>
//! Remove after use
import { useAuthStore } from "@/stores/auth";
import { useProfileStore } from "@/stores/profile";
import { useStatisticsStore } from "@/stores/statistics";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { jsontoexcel } from "vue-table-to-excel";
export default {
  components: {
    Datepicker,
  },
  setup() {
    const statistic = useStatisticsStore();
    //! Remove after use
    const profiles = useProfileStore();
    const auth = useAuthStore();
    return { statistic, profiles, auth };
  },

  mounted() {},

  created() {
    this.fetchData();
  },

  data: () => ({
    month: {
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
    },
  }),

  computed: {
    allStatistics() {
      return this.statistic.allStatistics;
    },
    getTotals() {
      let totalHours = 0;
      let totalAppointments = 0;
      let totalCancelledAppointments = 0;
      let totalNettoAppointments = 0;
      let totalCustomers = 0;
      for (let i = 0; i < this.allStatistics.length; i++) {
        if (!this.allStatistics[i].monthlyHours.hours) {
          totalHours += 0;
        } else {
          totalHours += parseInt(this.allStatistics[i].monthlyHours.hours);
        }
        totalAppointments += this.allStatistics[i].allAppointments;
        totalCancelledAppointments +=
          this.allStatistics[i].cancelledAppointments;
        totalNettoAppointments += this.allStatistics[i].nettoAppointments;
        totalCustomers += this.allStatistics[i].customers;
      }
      return {
        totalHours: totalHours,
        totalAppointments: totalAppointments,
        totalCancelledAppointments: totalCancelledAppointments,
        totalNettoAppointments: totalNettoAppointments,
        totalCustomers: totalCustomers,
      };
    },
  },

  watch: {},

  methods: {
    //! Remove after use
    async updateInfo() {
      await this.profiles.updateOutdatedData();
    },
    async getStatistics() {
      if (this.month !== null) {
        await this.fetchData();
      }
    },
    async fetchData() {
      let date = new Date(this.month.year, this.month.month + 1);
      let isoDate = date.toISOString().substring(0, 10);
      await this.statistic.getAllStatistics(isoDate);
    },
    async saveHours(item) {
      let hours = 0;
      if (item.monthlyHours.hours) {
        hours = item.monthlyHours.hours;
      }
      let hoursValue = {
        hours: hours,
      };
      let updatedHours = {
        hours: item.monthlyHours._id,
        update: hoursValue,
      };
      await this.statistic.updateMonthlyHours(updatedHours);
    },
    downloadExcel() {
      let data = [];
      for (let i = 0; i < this.allStatistics.length; i++) {
        let obj = {
          name:
            this.allStatistics[i].employee.firstName +
            " " +
            this.allStatistics[i].employee.lastName,
          monthlyHours: this.allStatistics[i].monthlyHours.hours,
          allApp: this.allStatistics[i].allAppointments,
          cancelledApp: this.allStatistics[i].cancelledAppointments,
          nettoApp: this.allStatistics[i].nettoAppointments,
          customers: this.allStatistics[i].customers,
        };
        data.push(obj);
      }
      //* Add the totals
      let totals = {
        name: "Totaal",
        monthlyHours: this.getTotals.totalHours,
        allApp: this.getTotals.totalAppointments,
        cancelledApp: this.getTotals.totalCancelledAppointments,
        nettoApp: this.getTotals.totalNettoAppointments,
        customers: this.getTotals.totalCustomers,
      };
      data.push(totals);
      let correctMonth = this.month.month + 1;
      let json = {
        dataSource: data,
        head: [
          "Beller",
          "Uren Gebeld",
          "Gemaakte Afspraken",
          "Geannuleerde afspraken",
          "Netto afspraken",
          "Klant geworden",
        ],
        fileName: "Sales Statistics " + correctMonth + "-" + this.month.year,
      };

      const { dataSource, head, fileName } = json;
      jsontoexcel.getXlsx(dataSource, head, fileName);
    },
  },
};
</script>

<style scoped>
.custom-card {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3) !important;
}
.customRow {
  background-color: antiquewhite;
}
</style>
